import React from "react";
import moment from "moment";
import { formatTime } from "../../../utils/tools";

function Attributes({ profile }) {
  const totalStudyMinutes = parseInt(profile.totalStudyTime, 10);
  const timeString = formatTime(totalStudyMinutes);

  return (
    <div className="attributes">
      <div className="item">
        <div className="title">First Name</div>
        <div className="value">{profile.learner.first_name}</div>
      </div>
      <div className="item">
        <div className="title">Last Name</div>
        <div className="value">{profile.learner.last_name}</div>
      </div>
      <div className="item">
        <div className="title">Email</div>
        <div className="value">{profile.learner.email}</div>
      </div>
      <div className="item">
        <div className="title">Learning start</div>
        <div className="value">{profile.learningStart ? moment(profile.learningStart).format('DD-MM-YYYY') : null}</div>
      </div>
      <div className="item">
        <div className="title">Total study time</div>
        <div className="value">{timeString}</div>
      </div>
      <div className="item">
        <div className="title">Recent activity</div>
        <div className="value">{profile.recentActivity ? moment(profile.recentActivity).format('DD-MM-YYYY') : null}</div>
      </div>
      <div className="item">
        <div className="title">Unique visits</div>
        <div className="value">{profile.uniqueVisits}</div>
      </div>
      <div className="item">
        <div className="title">Opens</div>
        <div className="value">{profile.opens}</div>
      </div>
      <div className="item">
        <div className="title">Completions</div>
        <div className="value">{profile.completions}</div>
      </div>
      <div className="item">
        <div className="title">Satisfaction score</div>
        <div className="value">{profile.satisfactionScore}</div>
      </div>
      <div className="item">
        <div className="title">Learning group</div>
        <div className="value">
          -
          {/* {profile.learningGroup.map((e) => (
            <div key={e} className="learning">{e}</div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

export default Attributes;
