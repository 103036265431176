import React, { useState } from 'react';
import { http_put } from '../../utils/api_client';
import { API_ENDPOINTS } from '../../utils/endpoints';

const Workspace = (props) => {
  const [workspaceName, setWorkspaceName] = useState(props.workspace_name || '');
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setWorkspaceName(value.trim());
  };

  const validateWorkspaceName = (name) => {
    const regex = /^[a-zA-Z0-9_-]+$/;
    return regex.test(name);
  };

  const updateWorkspace = async () => {
    if (!validateWorkspaceName(workspaceName)) {
      alert('Workspace name should not contain any space or special character.');
      return;
    }
    setIsSaving(true);
    try {
      const response = await http_put(API_ENDPOINTS.PUT_WORKSPACE_NAME, { workspace_name: workspaceName });
      if (response.status === 200) {
        if (response.data.message) {
          alert(response.data.message);
          window.location.href = `/${response.data.workspace_name}/settings/workspace`;
        }
        else {
          alert(response.data.error);
        }
      }
      else {
        alert('Failed to save workspace name. Please try again.');
      }
    }
    catch (error) {
      console.log("Error : ", error);
      alert('Failed to save workspace name. Please try again.');
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <>
      <div className='workspace-card'>
        <p>Need help with your workspace? Contact us at support@usernurture.com</p>
        <h4>Workspace name</h4>
        <div className='workspace-fields'>
          <input
            className="workspace-input"
            placeholder="workspace-directory"
            type="text"
            name="workspace_name"
            value={workspaceName}
            onChange={handleInputChange}
          />
          <button
            className="common-btn"
            type="button"
            onClick={updateWorkspace}
            disabled={isSaving}
          >
            <span>{isSaving ? 'Saving...' : 'Update'}</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default Workspace;