import React from 'react'
import { Provider } from "react-redux";
import { store } from "../../../../redux/store";
import PathContainer from './PathContainer';

const ShowPage = ({ id, audience, default_host }) => {
  return (
    <Provider store={store}>
      <PathContainer id={id} audience={audience} default_host={default_host}/>
    </Provider>
  )
}

export default ShowPage