import React, { useEffect, useState } from 'react';
import StepIndicator from '../../organisms/step-indicator/StepIndicator';
import Screen from '../../organisms/screen/Screen';
import Loader from '../../organisms/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSteps,
  setIndex,
  getIndex, getConfig,
  getLoading,
} from '../../../redux/walkthroughPreviewSlice';
import FontOption from '../../organisms/editor-design/options/FontOption';
import { getEmbedConfig, getEmbedIndex, getEmbedLoading, getEmbedSteps, setEmbedIndex } from '../../../redux/walkthroughEmbedPreviewSlice';
import { HUB_ENDPOINTS } from '../utils/endpoints';
import { getMaterial } from '../../../redux/materialSlice';
import { http_get, http_post } from '../utils/hub_client';
import { setResponseActiveStep } from '../../../redux/hubSlice';

const StepsViewer = ({ embed = false, id }) => {
  const dispatch = useDispatch();
  const steps = useSelector((state) => embed ? getEmbedSteps(state, id) : getSteps(state));
  const index = useSelector((state) => embed ? getEmbedIndex(state, id) : getIndex(state));
  const pageConfig = useSelector((state) => embed ? getEmbedConfig(state, id) : getConfig(state));
  const isLoading = useSelector((state) => embed ? getEmbedLoading(state, id) : getLoading(state));
  const material = useSelector((state) => getMaterial(state));

  const [activeStep, setActiveStep] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const getResponse = useSelector((state) => state.hub.responseData.completeStatus);

  useEffect(() => {
    const hubUrl = window.location.pathname.split('/')[2];
    if (hubUrl == 'hub' && material.id) {
      fetchResponse();
    }
    if (steps.length > 0) {
      setActiveStep(steps[0]);
      setCurrentIndex(0);
    }
  }, [steps, material.id]);

  const handleNextStep = async () => {
    const hubUrl = window.location.pathname.split('/')[2];
    if (hubUrl == 'hub') {
      await saveResponse();
    }
    if (currentIndex < steps.length - 1) {
      const nextStep = steps[currentIndex + 1];
      handleStepChange(nextStep, currentIndex + 1);
    }
  }
  
  const handleStepChange = async (_step, index) => {
    if (index === undefined) return;
    setActiveStep(steps[index]);
    setCurrentIndex(index);
    dispatch(embed ? setEmbedIndex({ data: index, id: id }) : setIndex(index));
  };

  const fetchResponse = async () => {
    const url = HUB_ENDPOINTS.GET_RESPONSE(material.id);
    try {
      const res = await http_get(url);
      const responseData = {
        minutes: res.data.data.minutes,
        response: res.data.data.hasOwnProperty('response') ? res.data.data.response : null
      };
      const completeStatus = responseData.response.reduce((acc, current) => {
        acc[current.id] = current.completion;
        return acc;
      }, {});
      dispatch(setResponseActiveStep({ ...responseData, completeStatus }));
      return responseData;
    } catch (error) {
      console.error("Error :", error);
    }
  }

  const saveResponse = async () => {

    const url = HUB_ENDPOINTS.SAVE_RESPONSE(material.id);
    const previousData = await fetchResponse();

    let newData;
    if (previousData && previousData.hasOwnProperty('response')) {
      newData = [...previousData.response]; // Create a copy of the previous response
      const existingIndex = newData.findIndex((e) => e.id === activeStep.id);
      if (existingIndex !== -1) {
        newData[existingIndex] = { "id": activeStep.id, "completion": true };
      } else {
        newData.push({ "id": activeStep.id, "completion": true });
      }
    } else {
      newData = [{ "id": activeStep.id, "completion": true }];
    }

    const newResponse = {
      "minutes": previousData ? previousData.minutes : 0,
      "response": newData
    }

    try {
      const res = await http_post(url, newResponse);
      const materials = res.data.data;
      dispatch(setResponseActiveStep({ ...materials }));
      const updatedGetResponse = { ...getResponse, [activeStep.id]: true };
      dispatch(setResponseActiveStep({ ...materials, completeStatus: updatedGetResponse }));
    } catch (error) {
      console.error("Error completing step:", error);
    }
  }

  const defaultColors = {
    primary: '#3BA4B4',
    background: '#F6F8F9',
    text: '#363841',
    btnColor: '#3BA4B4',
    btnTextColor: '#FFFFFF',
    config: pageConfig
  };

  const style = {
    backgroundColor: defaultColors.background,
    color: defaultColors.text
  };

  const saveCurrentStep = async (current_step) => {
    if(!material.id) return;
    const url = HUB_ENDPOINTS.SAVE_CURRENT_STEP(material.id);
    try {
      const res = await http_post(url, {
        current_step
      });
      const result = res.data.data;
      console.log(result);
    } catch (error) {
      console.error("Error saving current step:", error);
    }
  }

  useEffect(() => {
    const hubUrl = window.location.pathname.split('/')[2];
    if (hubUrl == 'hub' && material){
      saveCurrentStep(currentIndex + 1)
    }
  }, [activeStep, material])


  return (
    <React.Fragment>
      {isLoading && <Loader />}
      {!isLoading && (
        <div style={style}>
          <StepIndicator
            index={index}
            steps={steps}
            onChange={(index) => handleStepChange(activeStep, index)}
            configColors={defaultColors}
          />
          <Screen
            onChange={() => handleNextStep()}
            onActionBtn={(index) => handleStepChange(activeStep, index)}
            configColors={defaultColors}
            steps={steps}
            index={index}
            embed={embed}
            id={id}
          />
          <div hidden>
            <FontOption />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StepsViewer;
