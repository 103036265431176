import React, { useState } from 'react';
import { store } from '../../../redux/store';
import HubLayout from './HubLayout';
import { Provider } from 'react-redux';
import messageLogo from "../../../../assets/images/signin_message_logo.svg";
import { http_post } from '../utils/api_client';
import { API_ENDPOINTS } from '../utils/endpoints';
import DOMPurify from 'dompurify'; //library used to sanitize HTML

const PasswordlessSignIn = ({ design }) => {

  const [email, setEmail] = useState('');
  const [isSendingLink, setIsSendingLink] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [linkSent, setLinkSent] = useState(false);

  const CustomCSS = design ? JSON.parse(design.css) : null;
  const cleanHTML = DOMPurify.sanitize(CustomCSS);

  const configColors = {
    primary: '#3BA4B4',
    background: 'red',
    text: '#363841',
    btnColor: '#3BA4B4',
    btnTextColor: '#FFFFFF',
  };

  const style = {
    backgroundColor: configColors.background,
    color: configColors.text
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmail(emailValue);
    setEmailError(emailRegex.test(emailValue) ? null : 'Invalid email address');
  }

  const handleGetMagicLink = async (event) => {
    event.preventDefault();
    if (!email) return setEmailError('Email is required');

    setIsSendingLink(true);
    try {
      await http_post(API_ENDPOINTS.SEND_MAGIC_LINK, { email });
      setLinkSent(true);
    } catch (error) {
      console.error(error);
      alert('Error sending magic link');
    } finally {
      setIsSendingLink(false);
    }
  };

  return (
    <Provider store={store}>
      <HubLayout hideHeader={true} headerStyle={style} >
        <>
          <div className="un-signin__signin">
            <img className='company-logo' alt="Logo" src={design?.logo || '/assets/default_logo.svg'} style={{ display: "block", margin: "0 auto", height: 60 }} />
          </div>
          <div className="container">
            <div className="un-signin__magiclinkcard">
              <div className='content-wrapper'>
                <div className='logo-image'>
                  <img height="110" width="110" src={messageLogo} alt="Message Logo" />
                </div>
                {linkSent ? (
                  <>
                    <h2 className='magiccard_header'>Check your Email!</h2>
                    <h3 className='magiccard_subheader'>We’ve sent a new login link to your email inbox. Please click the link in the button to sign in.</h3>
                  </>
                ) : (
                  <>
                    <h2 className='magiccard_header'>{design?.details?.heading_text || "Looks like you need a new link"}</h2>
                    <h3 className='magiccard_subheader'>{design?.details?.sub_heading_text || "Enter your email address and we’ll send you a new link to sign in"}</h3>
                    <div className='auth-form'>
                      <form className='form-group' onSubmit={handleGetMagicLink}>
                        <div className='input-container'>
                          <label className='label-email' htmlFor='email'>Email Address</label>
                          <input
                            type='email'
                            name='email'
                            className='card-input email-input'
                            value={email}
                            onChange={handleEmailChange}
                            required
                          />
                          {emailError && <span className='error-message'>{emailError}</span>}
                          <button className='send-button' type='submit' disabled={isSendingLink || emailError}>
                            {isSendingLink ? 'Sending...' : 'SEND LINK'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {CustomCSS && <style dangerouslySetInnerHTML={{ __html: cleanHTML }} />}
        </>
      </HubLayout>
    </Provider>
  )
}

export default PasswordlessSignIn