import React from "react";
import InviteTeam from "./components/team/InviteTeam";
import AdminUsers from "./components/team/AdminUsers";
import { ToastContainer } from 'react-toastify';
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import './Settings.scss';
import { store } from "../../../../redux/store";

const TeamPage = () => {
  return (
    <Provider store={store}>
      <div className="container-team">
        <InviteTeam />
        <AdminUsers />
        <ToastContainer />
      </div>
    </Provider>
  );
}

export default TeamPage;