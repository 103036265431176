import React, { useState, useRef, useEffect } from 'react';
import { EyeIcon, LinkIcon, MoreDotIcon, PencilIcon } from '../../assets/Icons';
import { API_ENDPOINTS, HUB_VIEW_ENDPOINTS } from '../../pages/utils/endpoints';
import { http_delete, http_get } from '../../pages/utils/api_client';

const Card = ({ item, baseUrl, companySlug, setUpdatedData, thumbnail_url, favicon_url }) => {
  const backgroundUrl = item?.data[0]?.backgroundUrl;
  const defaultImageURL = backgroundUrl || require('../../assets/1.png');
  const { name, material_type, status, description, slug, opens, unique_visits, completions } = item;
  const [visible, setVisible] = useState(false);
  const menuRef = useRef(null);
  const [copyVisible, setCopyVisible] = useState(false);

  const backgroundImage = (material_type === "Walkthrough" && thumbnail_url == "/assets/not-found.png") ? defaultImageURL : thumbnail_url;

  const navPublishedView = () => {
    window.location.pathname = `${baseUrl}`;
  };

  const navEditView = () => {
    window.location.pathname = `${baseUrl}/edit`;
  };

  const toggleMenu = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLinkCopy = ({ iframe = false }) => {
    const link = `${window.location.origin}${HUB_VIEW_ENDPOINTS.VIEW_MATERIAL(companySlug, item.slug)}`;
    if (iframe) {
      const data = `<iframe src="${link}" height="400" width="100%" title="${name}" frameborder="0"></iframe>`;
      navigator.clipboard.writeText(data);
    } else {
      navigator.clipboard.writeText(link);
    }
    setVisible(false);

    setCopyVisible(true);
    setTimeout(() => {
      setCopyVisible(false);
    }, 2000);
  };

  const handleDelete = () => {
    if (confirm(`Are you sure you want to delete this material: ${name}?`)) {
      http_delete(API_ENDPOINTS.DELETE_MATERIAL(slug))
        .then((res) => {
          if (res && res.data && res.data.message) {
            alert(res.data.message);
            http_get(API_ENDPOINTS.GET_LEARNING_MATERIALS, { category: material_type === "Walkthrough" ? "walkthroughs" : material_type.toLowerCase() })
              .then((res) => {
                setUpdatedData(res.data);
              })
          }
          else {
            alert(res.data.error);
          }
        })
        .finally(() => { });
    }
  }

  const handleDuplicate = () => {
    http_get(API_ENDPOINTS.DUPLICATE_LEARNING_MATERIAL(slug))
      .then((res) => {
        if (res && res.data && res.data.message) {
          alert(res.data.message);
          http_get(API_ENDPOINTS.GET_LEARNING_MATERIALS, { category: material_type === "Walkthrough" ? "walkthroughs" : material_type.toLowerCase() })
            .then((res) => {
              setUpdatedData(res.data);
            })
        }
        else {
          alert(res.data.error);
        }
      })
      .finally(() => { });
  }

  return (
    <div className="un-card">
      <div
        className="card-header"
        style={{
          backgroundImage: `url(${backgroundImage})`
        }}
      >
        <div className={`state ${status.toLowerCase()}`}>
          <span></span>
          {status}
        </div>
        <div className={"link-icon"} onClick={handleLinkCopy}>
          <LinkIcon />
          <span className={copyVisible ? 'show' : ''}>Copied!</span>
        </div>
        <div
          className="content-logo"
          style={{
            backgroundImage: `url(${favicon_url})`
          }}
        ></div>
      </div>
      <div className="card-body">
        <div className="top-section">
          <div className="title">
            <h3>{name}</h3>
            <p>{material_type}</p>
          </div>
          <div className="actions">
            <div onClick={navPublishedView}>
              <EyeIcon />
            </div>
            <div onClick={navEditView}>
              <PencilIcon />
            </div>
            <div onClick={toggleMenu}>
              <MoreDotIcon />
            </div>
          </div>
          {visible && (
            <div className="menu" ref={menuRef}>
              <div className="menu-item">
                <a href="#view" onClick={navPublishedView}>View the material</a>
              </div>
              <div className="menu-item">
                <a href="#edit" onClick={navEditView}>Edit the material</a>
              </div>
              <div className="menu-item">
                <a href="#publish">Publish the material</a>
              </div>
              <div className="menu-item">
                <a href="#copy-url" onClick={handleLinkCopy}>Copy URL</a>
              </div>
              <div className="menu-item" onClick={() => handleLinkCopy({ iframe: true })}>
                <a href="#copy-embed">Copy embed code</a>
              </div>
              <div className="menu-item" onClick={() => handleDuplicate()}>
                <a href="#duplicate">Duplicate the content</a>
              </div>
              <div className="menu-item" onClick={() => handleDelete()}>
                <a href="#delete">Delete the content</a>
              </div>
            </div>
          )}
        </div>
        <div className="details">
          <p>
            {description || 'These meticulously crafted resources are designed to aid students, educators.'}
          </p>
        </div>
      </div>
      <div className="card-footer">
        <div>
          <p className="text">Opens</p>
          <p className="text blue">{opens}</p>
        </div>
        <div className="divider"></div>
        <div>
          <p className="text">Completions</p>
          <p className="text blue">{completions}</p>
        </div>
        <div className="divider"></div>
        <div>
          <p className="text">Uniques</p>
          <p className="text blue">{unique_visits}</p>
        </div>

      </div>
    </div>
  );
};

export default Card;
