import React from "react";

function HeaderData({ profile, setIsActive }) {
  return (
    <div className="modal-header">
      <div className="left">
        <img src={profile.learner.imageUrl} />
        <div className="title">
          <h5>{profile.learner.name}</h5>
          <p>{profile.learner.email}</p>
        </div>
        <div className={`activity-status ${profile.activityStatus}`} style={{ textTransform: "capitalize" }}>
          {profile.activityStatus}
        </div>
      </div>
      <div className="right">
        <div className="icons">
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0228 20.7916L25.8654 11.1532C26.2982 10.7655 26.5692 10.2105 26.6193 9.60993C26.6693 9.00936 26.4942 8.4121 26.1323 7.94896C25.7705 7.48582 25.2513 7.19453 24.6885 7.13888C24.1257 7.08324 23.5652 7.26777 23.1297 7.65207L10.3189 19.041C10.0786 19.2551 9.88541 19.5228 9.75282 19.8253C9.62023 20.1278 9.55151 20.4577 9.55151 20.7916C9.55151 21.1255 9.62023 21.4554 9.75282 21.7579C9.88541 22.0604 10.0786 22.3281 10.3189 22.5422L23.1297 33.9311C23.565 34.3172 24.1262 34.5033 24.69 34.4483C25.2538 34.3933 25.7741 34.1019 26.1366 33.6379C26.4991 33.174 26.6741 32.5756 26.6233 31.9741C26.5724 31.3725 26.2998 30.8171 25.8654 30.4299L15.0228 20.7916Z"
              fill="#64748B"
            />
          </svg>
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.8756 19.8656L15.0331 29.504C14.6003 29.8918 14.3292 30.4467 14.2792 31.0473C14.2291 31.6479 14.4042 32.2451 14.7661 32.7083C15.128 33.1714 15.6471 33.4627 16.2099 33.5183C16.7727 33.574 17.3332 33.3895 17.7687 33.0052L30.5796 21.6162C30.8198 21.4021 31.013 21.1344 31.1456 20.8319C31.2782 20.5294 31.3469 20.1995 31.3469 19.8656C31.3469 19.5317 31.2782 19.2018 31.1456 18.8993C31.013 18.5969 30.8198 18.3291 30.5796 18.115L17.7687 6.72612C17.3334 6.33998 16.7722 6.15396 16.2084 6.20893C15.6446 6.26389 15.1243 6.55535 14.7619 7.01928C14.3994 7.48321 14.2243 8.08166 14.2752 8.68317C14.326 9.28469 14.5986 9.84008 15.0331 10.2273L25.8756 19.8656Z"
              fill="#64748B"
            />
          </svg>
          <svg
            onClick={() => setIsActive(false)}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.2356 17.0585L26.2282 10.0659C26.4887 9.8001 26.6338 9.44217 26.6319 9.06996C26.63 8.69774 26.4813 8.3413 26.2181 8.0781C25.9549 7.8149 25.5985 7.6662 25.2263 7.66432C24.8541 7.66244 24.4961 7.80753 24.2303 8.06806L17.2377 15.0606L10.2451 8.06806C9.97929 7.80753 9.62137 7.66244 9.24916 7.66432C8.87694 7.6662 8.5205 7.8149 8.2573 8.0781C7.9941 8.3413 7.8454 8.69774 7.84352 9.06996C7.84164 9.44217 7.98673 9.8001 8.24726 10.0659L15.2398 17.0585L8.24726 24.0511C7.98673 24.3169 7.84164 24.6749 7.84352 25.0471C7.8454 25.4193 7.9941 25.7757 8.2573 26.0389C8.5205 26.3021 8.87694 26.4508 9.24916 26.4527C9.62137 26.4546 9.97929 26.3095 10.2451 26.049L17.2377 19.0564L24.2303 26.049C24.4961 26.3095 24.8541 26.4546 25.2263 26.4527C25.5985 26.4508 25.9549 26.3021 26.2181 26.0389C26.4813 25.7757 26.63 25.4193 26.6319 25.0471C26.6338 24.6749 26.4887 24.3169 26.2282 24.0511L19.2356 17.0585Z"
              fill="#64748B"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default HeaderData;
