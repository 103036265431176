import React, { useEffect, useState } from "react";
import ExportModal from "./toolbar-extra/ExportModal";
import MultiCombobox from "../MultiCombobox";
import DateDropdown from "../DateDropdown";
import SearchInput from "../SearchInput";

const activityStatusOptions = [
  {
    value: "engaged",
    label: "Engaged",
  },
  {
    value: "at_risk",
    label: "At Risk",
  },
  {
    value: "neutral",
    label: "Neutral",
  },
];

function Toolbar({
  table,
  onSearchChange,
  onLearningStartDateChange,
  onLearningEndDateChange,
  onRecentStartDateChange,
  onRecentEndDateChange,
  onActivityStatusChange
}) {
  const [activityFilters, setActivityFilters] = useState([]);

  useEffect(() => {
    const column = table.getColumn("activityStatus");
    if (activityFilters.length > 0) {
      onActivityStatusChange(activityFilters);
      // column.setFilterValue(activityFilters);
    } else {
      onActivityStatusChange([]);
      // column.setFilterValue(undefined);
    }
  }, [activityFilters, table]);

  return (
    <div className="section-data-toolbar">
      <div className="page-padding">
        <div className="flex-data-toolbar">
          <div className="left-data-toolbar">
            <SearchInput
              placeholder="Search Learner"
              value={table.getColumn("learner")?.getFilterValue() ?? ""}
              setValue={(value) => {
                table.getColumn("learner").setFilterValue(value);
                onSearchChange(value)
              }}
            />
            <div className="toolbar-filter-section">
              <MultiCombobox
                options={activityStatusOptions}
                filters={activityFilters}
                setFilters={setActivityFilters}
                placeholder="Activity Status"
                isSelectAllEnabled
              />
              <DateDropdown
                column={table.getColumn("learningStart")}
                placeholder="Learning Start"
                name={"learning"}
                onLearningStartDateChange={onLearningStartDateChange}
                onLearningEndDateChange={onLearningEndDateChange}
              />
              <DateDropdown
                column={table.getColumn("recentActivity")}
                placeholder="Recent Activity"
                name={"recent"}
                onRecentStartDateChange={onRecentStartDateChange}
                onRecentEndDateChange={onRecentEndDateChange}
              />
            </div>
          </div>
          <div className="right-data-toolbar">
            <ExportModal table={table} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
