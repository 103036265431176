import React, { useEffect, useState } from "react";
import Paths from "./tables/paths";
import Walkthroughs from "./tables/walkthroughs";
import Docs from "./tables/docs";
import debounce from 'lodash.debounce';
import { AUDIENCE_GET_ACTIVITY_DETIALS } from "../../../../endpoints";
import { AuthContext } from "../../../../contexts";
import axios from "axios";
import Loader from "../../../../organisms/loader/Loader";

function Activity({ profile }) {
  const [activityData, setActivityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = React.useContext(AuthContext);

  const fetchActivityData = debounce(async () => {
    try {
      const options = {
        url: `${AUDIENCE_GET_ACTIVITY_DETIALS(profile?.id)}`,
        headers: { Authorization: token }
      };
      const response = await axios.request(options);
      setActivityData(response.data);
    } catch (error) {
      console.error("Error fetching file details:", error);
      alert("Failed to fetch file details. Please try again.");
    } finally {
      setLoading(false);
    }
  }, 500)

  useEffect(() => {
    fetchActivityData();
  }, [])

  return (
    <div className="activity">
      {loading ? <Loader /> :
        <>
          <Paths data={activityData?.paths} />
          <Walkthroughs data={activityData?.walkthrough} />
          <Docs data={activityData?.docs} />
        </>
      }
    </div>
  );
}

export default Activity;
