import React from 'react';
import { UploadIcon } from '../../../assets/Icons';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getMaterial, updateMaterial } from '../../../../redux/materialSlice';

const validateFileType = (fileType) => {
  const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
  return allowedTypes.includes(fileType);
};

const validateFileSize = (fileSize) => {
  const fileSizeLimit = 15 * 1024 * 1024; // 15 MB
  return fileSize <= fileSizeLimit;
};

export default function UploadOption({ onChange, image_type }) {

  const dispatch = useDispatch();

  const materials = useSelector((state) => getMaterial(state));

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    handleFile(file);
  }

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('image_type', image_type);
    const url = `/api/v1/uploads/learning_material/${materials.slug}`;
    const response = await axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .finally(() => {
      });
    return response.data;
  };

  const handleDeleteFile = async () => {
    if (confirm(`Are you sure you want to delete ${image_type} image?`)) {
      const url = `/api/v1/learning_materials/${materials.slug}/remove_attachment`;
      const response = await axios
        .delete(url, {
          data: {
            image_type: image_type,
          },
        });
      if (response.status == 200) {
        alert(`${response.data.message}`)
        window.location.reload();
      }
      else {
        alert("Something went wrong.")
      }
    }
  }

  const handleFile = async (file) => {
    if (!validateFileType(file?.type)) {
      alert('Only JPG, PNG, GIF, and JPEG images are allowed.');
      return;
    }

    if (!validateFileSize(file.size)) {
      alert('File size exceeds the maximum limit of 15 MB.');
      return;
    }

    const response = await uploadImage(file);
    if (response) {
      onChange(response.image_url);
      const keyName = image_type == 'favicon' ? 'favicon_url' : 'cover_url';
      dispatch(updateMaterial({ keyName: response.image_url }))
      alert(`${image_type} added successfully.`)
    }
    else (
      alert('Something went wrong.')
    )
  }

  return (
    <>
      <div className='flex'>
        <label className="btn btn-primary" style={{ paddingTop: 5, paddingBottom: 5 }}>
          <UploadIcon /> Upload
          <input
            type="file"
            accept=".jpg,.jpeg,.png,.gif"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </label>
        {(materials.cover_url == "/assets/not-found.png" || image_type !== "cover") ? null :
          <label
            className="btn btn-primary"
            style={{ marginLeft: 5, paddingTop: 5, paddingBottom: 5 }}
            onClick={handleDeleteFile}>
            X
          </label>
        }
      </div>
    </>
  )
}