import React, { useEffect, useState, useCallback } from "react";
import MultiCombobox from "../MultiCombobox";
import debounce from 'lodash.debounce';
import axios from "axios";
import { AuthContext } from "../../../../contexts";
import { AUDIENCE_GET_RESPONSE_DETIALS } from "../../../../endpoints";
import Loader from "../../../../organisms/loader/Loader";
import { formatDate, materialName } from "../../../utils/tools";

function Responses({ profile }) {
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = React.useContext(AuthContext);

  const fetchResponseData = debounce(async () => {
    try {
      const options = {
        url: `${AUDIENCE_GET_RESPONSE_DETIALS(profile?.id)}`,
        headers: {
          Authorization: token
        }
      };
      const response = await axios.request(options);
      setResponseData(response.data.records);
    } catch (error) {
      console.error("Error fetching response data:", error);
      alert("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, 500)

  useEffect(() => {
    fetchResponseData();
  }, []);

  const getMaterialName = (name) => materialName[name] || "";

  return (
    <div className="responses">
      <h5 className="title">Responses</h5>
      {/* <div className="option">
        <div className=""></div>
        <MultiCombobox
          options={[{ value: "", label: "" }]}
          filters={[]}
          placeholder="Modified"
          isSelectAllEnabled
        />
      </div> */}
      {loading ? <Loader /> :
        <div className="content">
          {responseData.length === 0 ?
            <div className="item">No Response Found.</div>
            : responseData.map((data, index) => {
              return (
                <div className="item" key={index}>
                  <div className="left">
                    <h5>{data?.attempt?.question?.description} ({getMaterialName(data?.learning_material_type)} : {data?.learning_material_name})</h5>
                    <p>{data?.attempt?.answer}</p>
                  </div>
                  <div className="right">
                    <p>{formatDate(data?.attempt?.created_at)}</p>
                  </div>
                </div>
              )
            })}
        </div>
      }
    </div>
  );
}

export default Responses;
