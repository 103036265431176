import { configureStore } from '@reduxjs/toolkit';

import stepsReducer from './stepsSlice.js';
import toolsReducer from './toolsSlice.js';
import materialReducer from './materialSlice.js';
import onboardingReducer from './onboardingSlice.js';
import walkthroughPreviewReducer from './walkthroughPreviewSlice.js';
import walkthroughEmbedPreviewReducer from './walkthroughEmbedPreviewSlice.js';
import companyReducer from './companySlice.js';
import hubReducer from './hubSlice.js';
import notifyReducer from './notifySlice.js';
import profileReducer from './profileSlice.js';
import teamReducer from './teamSlice.js';
import dashboardSlice from './dashboardSlice.js';

export const store = configureStore({
  reducer: {
    base: stepsReducer,
    company: companyReducer,
    tools: toolsReducer,
    material: materialReducer,
    onboarding: onboardingReducer,
    walkthroughPreview: walkthroughPreviewReducer,
    walkthroughEmbedPreview: walkthroughEmbedPreviewReducer,
    hub: hubReducer,
    notify: notifyReducer,
    profile: profileReducer,
    team: teamReducer,
    dashboard: dashboardSlice,
  }
});
