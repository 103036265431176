// generate splice

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LargeTextLayerContentDefaults } from '../components/pages/paths/components/authoring/content/fields/LargeTextContent';

const generateId = () => {
  return Math.floor(Math.random() * 100000000);
};

const updateHistory = (state) => {
  if (state.historyPosition < state.historyArr.length - 1) {
    state.historyArr.splice(state.historyPosition + 1);
  }

  if (
    state.historyArr.length > 0 &&
    JSON.stringify(state.historyArr[state.historyArr.length - 1]) ===
    JSON.stringify(state.steps)
  ) {
    return;
  }

  if (state.historyArr.length > 30) {
    state.historyArr.shift();
    state.historyPosition--;
  }

  state.historyArr.push(state.steps);
  state.historyPosition++;
};

const defaultStep = {
  id: generateId(),
  name: 'Step 1',
  title: 'Title 1',
  description: 'Add a description to this step',
  backgroundUrl: '',
  layers: []
};

const defaultConfig = {
  "walkthrough": [
    {
      sectionId: 'tn',
      sectionTitle: 'Top Navigation',
      values: [
        {
          id: 'tnBGColor',
          title: 'BG Color',
          value: '#FFFFFF',
          type: 'color'
        },
        {
          id: 'tnLinkColor',
          title: 'Link Color',
          value: '#000000',
          type: 'color'
        },
        {
          id: 'tnTextColor',
          title: 'Text Color',
          value: '#000000',
          type: 'color'
        }
      ]
    },
    {
      sectionId: 'mca',
      sectionTitle: 'Main Content Area',
      values: [
        {
          id: 'mcaBgColor',
          title: 'BG Color',
          value: '#FFFFFF',
          type: 'color'
        },
        {
          id: 'mcaStepHeadlineFont',
          title: 'Step Headline Font',
          value: 'Open Sans',
          type: 'font'
        },
        {
          id: 'mcaStepHeadlineFontSize',
          title: 'Step Headline Font Size',
          value: '14px',
          type: 'px-size'
        },
        {
          id: 'mcaStepHeadlineColor',
          title: 'Step Headline Color',
          value: '#000000',
          type: 'color'
        },
        {
          id: 'mcaStepDescriptionFont',
          title: 'Step Description Font',
          value: 'Open Sans',
          type: 'font'
        },
        {
          id: 'mcaStepDescriptionFontSize',
          title: 'Step Description Font Size',
          value: '16px',
          type: 'px-size'
        },
        {
          id: 'mcaStepDescriptionColor',
          title: 'Step Description Color',
          value: '#000000',
          type: 'color'
        },
        {
          id: 'mcaInstructionFont',
          title: 'Instruction Font',
          value: 'Open Sans',
          type: 'font'
        },
        {
          id: 'mcaInstructionFontSize',
          title: 'Instruction Font Size',
          value: '12px',
          type: 'px-size'
        },
        {
          id: 'mcaInstructionColor',
          title: 'Instruction Color',
          value: '#FFFFFF',
          type: 'color'
        }
      ]
    },
    {
      sectionId: 'ea',
      sectionTitle: 'Embedded Area',
      values: [
        {
          id: 'eaBGColor',
          title: 'BG Color',
          value: '#FFFFFF',
          type: 'color'
        },
        {
          id: 'eaTextColor',
          title: 'Text Color',
          value: '#000000',
          type: 'color'
        },
        {
          id: 'eaIconColor',
          title: 'Icon Color',
          value: '#000000',
          type: 'color'
        }
      ]
    }
  ],
  "path": [
    {
      sectionId: 'phc',
      sectionTitle: 'Path Header Customization',
      values: [
        {
          id: 'phcHeadline',
          title: 'P. Headline',
          value: '',
          type: 'text'
        },
        {
          id: 'phcSubHeaderDescription',
          title: 'P. Sub Header Description',
          value: '',
          type: 'textarea',
          direction: 'column'
        },
        {
          id: 'phcHeaderLogo',
          title: 'P. Header Logo',
          value: '',
          type: 'upload',
          image_type: 'favicon'
        },
        {
          id: 'phcCoverImage',
          title: 'P. Cover Image',
          value: '',
          type: 'upload',
          image_type: 'cover'
        }
      ]
    },
    {
      sectionId: 'ha',
      sectionTitle: 'Header Area',
      values: [
        {
          id: 'haBGColor',
          title: 'BG Color',
          value: '#23455B',
          type: 'color'
        },
        {
          id: 'haLinkColor',
          title: 'Link Color',
          value: '#000000',
          type: 'color'
        },
        {
          id: 'haTextColor',
          title: 'Text Color',
          value: '#FFFFFF',
          type: 'color'
        },
        {
          id: 'haFont',
          title: 'Font',
          value: 'Open Sans',
          type: 'font'
        },
      ]
    },
    {
      sectionId: 'ca',
      sectionTitle: 'Content Area',
      values: [
        {
          id: 'caHeaderColor',
          title: 'Header Color',
          value: '#F3F3F3',
          type: 'color'
        },
        {
          id: 'caHeaderFontSize',
          title: 'Font Size',
          value: '18px',
          type: 'px-size'
        },
        {
          id: 'caTextColor',
          title: 'Text Color',
          value: '#ACACAC',
          type: 'color'
        },
        // {
        //   id: 'caTextFontSize',
        //   title: 'Font Size',
        //   value: '14px',
        //   type: 'px-size'
        // },
      ]
    },
    {
      sectionId: 'ba',
      sectionTitle: 'Bottom Area',
      values: [
        {
          id: 'baHeaderColor',
          title: 'BG Color',
          value: '#F6F8F9',
          type: 'color'
        }
      ]
    }
  ]
};

export const fetchActiveStep = createAsyncThunk(
  'toolsSlice/fetchActiveStep',
  async (_, { getState }) => {
    const state = getState();
    return { activeStep: state.tools.activeStep, activeLayer: state.tools.activeLayer };
  }
);

export const stepsSlice = createSlice({
  name: 'base',
  initialState: {
    steps: [defaultStep],
    mainConfig: defaultConfig,
    layerConfig: [],
    historyArr: [],
    historyPosition: -1,
    activeStep: defaultStep,
    activeLayer: null
  },
  reducers: {
    loadSteps: (state, action) => {
      const steps = action.payload;
      if (!steps) return;
      if (steps.length === 0) return;
      state.steps = steps;
    },
    setActiveStepById: (state, action) => {
      const stepId = action.payload;
      const index = state.steps.findIndex((step) => step.id === stepId);
      state.steps[index].isActive = true;
      updateHistory(state);
    },
    addStep: (state, action) => {
      const { material_type } = action.payload;
      const newStep = material_type === "paths" ? {
        id: generateId(),
        name: `Step ${state.steps.length + 1}`,
        title: `Title ${state.steps.length + 1}`,
        layers: [LargeTextLayerContentDefaults('main')]
      }
        :
        {
          id: generateId(),
          name: `Step ${state.steps.length + 1}`,
          title: `Title ${state.steps.length + 1}`,
          description: 'Add a description to this step',
          layers: []
        };
      state.steps.push(newStep);
      updateHistory(state);
    },
    deleteStep: (state, action) => {
      const stepId = action.payload;
      const index = state.steps.findIndex((step) => step.id === stepId);
      state.steps.splice(index, 1);
      updateHistory(state);
    },
    updateStep: (state, action) => {
      const stepId = action.payload.id;
      const index = state.steps.findIndex((step) => step.id === stepId);
      state.steps[index] = action.payload;
      updateHistory(state);
    },
    addLayer: (state, action) => {
      const stepId = action.payload.stepId;
      const layer = action.payload.layer;
      const index = state.steps.findIndex((step) => step.id === stepId);
      state.steps[index].layers.push(layer);
      updateHistory(state);
    },
    addLayerAfter: (state, action) => {
      const { stepId, afterLayerId, newLayer } = action.payload;
      const step = state.steps.find(step => step.id === stepId);
      if (step) {
        const index = step.layers.findIndex(layer => layer.id === afterLayerId);
        if (index !== -1) {
          step.layers.splice(index + 1, 0, newLayer);
        }
        updateHistory(state);
      }
    },
    addLayerToActive: (state, action) => {
      const activeStep = state.activeStep;
      const layer = action.payload.layer;
      const index = state.steps.findIndex((step) => step.id === activeStep.id);
      state.steps[index].layers.push(layer);
      updateHistory(state);
    },
    updateLayer: (state, action) => {
      const stepId = action.payload.stepId;
      const layer = action.payload.layer;
      const layerId = layer.id;
      const index = state.steps.findIndex((step) => step.id === stepId);
      const layerIndex = state.steps[index].layers.findIndex(
        (layer) => layer.id === layerId
      );
      state.steps[index].layers[layerIndex] = layer;
      updateHistory(state);
    },
    removeLayer: (state, action) => {
      const stepId = action.payload.stepId;
      const layerId = action.payload.layerId;
      const index = state.steps.findIndex((step) => step.id === stepId);
      const layerIndex = state.steps[index].layers.findIndex(
        (layer) => layer.id === layerId
      );
      state.steps[index].layers.splice(layerIndex, 1);
      updateHistory(state);
    },
    swapLayers: (state, action) => {
      const { stepId, layerId1, layerId2 } = action.payload;
      const step = state.steps.find(step => step.id === stepId);
      if (step) {
        const index1 = step.layers.findIndex(layer => layer.id === layerId1);
        const index2 = step.layers.findIndex(layer => layer.id === layerId2);
        if (index1 !== -1 && index2 !== -1) {
          const temp = step.layers[index1];
          step.layers[index1] = step.layers[index2];
          step.layers[index2] = temp;
          updateHistory(state);
        }
      }
    },
    updateLayers: (state, action) => {
      const stepId = action.payload.stepId;
      const layers = action.payload.layers;
      const index = state.steps.findIndex((step) => step.id === stepId);
      state.steps[index].layers = layers;
      updateHistory(state);
    },
    updateBackgroundURL: (state, action) => {
      const stepId = action.payload.stepId;
      const backgroundUrl = action.payload.url;
      const index = state.steps.findIndex((step) => step.id === stepId);
      state.steps[index].backgroundUrl = backgroundUrl;
      updateHistory(state);
    },
    undo: (state, action) => {
      if (state.historyArr.length === 0) return;

      if (state.historyPosition > 0) {
        state.historyPosition--;
      }
      state.steps = state.historyArr[state.historyPosition];
    },
    redo: (state, action) => {
      if (state.historyArr.length === 0) return;
      if (state.historyPosition < state.historyArr.length - 1) {
        state.historyPosition++;
      }
      state.steps = state.historyArr[state.historyPosition];
    },
    setMainConfig: (state, action) => {
      const { config, type } = action.payload;
      if (!config) return;
      if (config.length === 0) {
        state.mainConfig = [...defaultConfig[type]];
        return;
      }

      state.mainConfig = config;
    },
    updateMainConfig: (state, action) => {
      const { sectionId, titleId, value } = action.payload;
      const sectionIndex = state.mainConfig.findIndex(
        (section) => section.sectionId === sectionId
      );
      if (sectionIndex === -1) return;

      const titleIndex = state.mainConfig[sectionIndex].values.findIndex(
        (title) => {
          return title.id === titleId;
        }
      );
      if (titleIndex === -1) return;

      state.mainConfig[sectionIndex].values[titleIndex].value = value;
    },
    setLayerConfig: (state, action) => {
      const config = action.payload;
      if (config === null) {
        return;
      }

      return { ...state, layerConfig: config };
    },
    updateLayerConfig: (state, action) => {
      const { sectionId, titleId, value } = action.payload;
      const sectionIndex = state.layerConfig.findIndex(
        (section) => section.sectionId === sectionId
      );
      if (sectionIndex === -1) return;

      const titleIndex = state.layerConfig[sectionIndex].values.findIndex(
        (title) => {
          return title.id === titleId;
        }
      );
      if (titleIndex === -1) return;

      state.layerConfig[sectionIndex].values[titleIndex].value = value;
    },
    removeLayerConfig: (state, action) => {
      state.layerConfig = [];
    }
  },
  extraReducers: {
    [fetchActiveStep.fulfilled]: (state, action) => {
      const { activeStep, activeLayer } = action.payload;
      if (activeStep) {
        state.activeStep = activeStep;
      }
      if (activeLayer) {
        state.activeLayer = activeLayer;
      }
    }
  }
});

export const allSteps = (state) => state.base.steps;

export const selectStep = (state, stepId) => {
  const steps = allSteps(state);
  return steps.find((step) => step.id === stepId);
};

export const selectLayers = (state, stepId) => {
  const step = selectStep(state, stepId);
  return step?.layers || [];
};

export const getActiveBackgroundURL = (state, stepId) => {
  const step = selectStep(state, stepId);
  return step?.backgroundUrl;
};

export const getMainConfig = (state) => state.base.mainConfig;

export const getLayerConfig = (state) => state.base.layerConfig;

export const getLayerObjConfig = (state, layer) => {
  const layerConfig = getLayerConfig(state);
  const objConfig = {};
  layerConfig.forEach((section) => {
    if (section.layerId !== layer.id) return {};
    section.values.forEach((value) => {
      objConfig[value.id] = value.value;
    });
  });
  return objConfig;
};

export const getActiveStep = (state) => {
  return state.tools.activeStep || state.base.steps[0];
};

export const {
  addStep,
  addLayerToActive,
  deleteStep,
  updateStep,
  addLayer,
  addLayerAfter,
  updateLayer,
  removeLayer,
  swapLayers,
  updateLayers,
  loadSteps,
  setActiveStepById,
  updateBackgroundURL,
  undo,
  redo,
  updateMainConfig,
  setMainConfig,
  setLayerConfig,
  updateLayerConfig,
  removeLayerConfig
} = stepsSlice.actions;

export default stepsSlice.reducer;
