import React, { useState } from 'react';
import { CONSTS } from '../../pages/utils/endpoints';
import { getCompany } from '../../../redux/companySlice';
import { useSelector } from 'react-redux';
import Card from '../card/Card';
import { ENDPOINTS } from '../../pages/utils/endpoints';

const LMGrid = ({ data }) => {
  const [updatedData, setUpdatedData] = useState(data);
  const company = useSelector((state) => getCompany(state));

  const getBaseUrl = (item) => {
    switch (item.material_type.toLowerCase()) {
      case CONSTS.WALKTHROUGH:
        return ENDPOINTS.VIEW_WALKTHROUGH(company.slug, item.slug);
      case CONSTS.VIDEO:
        return ENDPOINTS.VIEW_WALKTHROUGH(company.slug, item.slug);
      case CONSTS.PATHS:
        return ENDPOINTS.VIEW_PATH(company.slug, item.slug);
      default:
        break;
    }
  }

  const renderRows = () => {
    let rows = [];
    let row = [];
    updatedData.map((item, index) => {
      const baseUrl = getBaseUrl(item);
      row.push(
        <Card
          key={index}
          item={item}
          baseUrl={baseUrl}
          companySlug={company.slug}
          thumbnail_url={item.thumbnail_url}
          favicon_url={item.favicon_url}
          setUpdatedData={setUpdatedData}
        />
      );
      if ((index + 1) % 4 === 0) {
        rows.push(row);
        row = [];
      }
    });
    if (row.length > 0) {
      rows.push(row);
    }
    return rows;
  }

  return (
    <div>
      <div className="un-grid-container">{renderRows()}</div>
    </div>
  );
};

export default LMGrid;
