import React from "react";
import HubLayout from "../HubLayout";
import PreviewWalkthrough from "./PreviewWalkthrough";
import { useSelector } from "react-redux";
import { getConfig } from "../../../../redux/walkthroughPreviewSlice";
import { getValue } from "../../utils/tools";

const WalkthroughContainer = ({ id, children, audience, default_host }) => {
  
  const pageConfig = useSelector((state) => getConfig(state));

  const configColors = {
    primary: '#3BA4B4',
    background: '#F6F8F9',
    text: '#363841',
    btnColor: '#3BA4B4',
    btnTextColor: '#FFFFFF',
    config: pageConfig
  };

  const tnBGColor = getValue(configColors, 'tn', 'tnBGColor');
  const tnTextColor = getValue(configColors, 'tn', 'tnTextColor');

  const style = {
    backgroundColor: tnBGColor || configColors.background,
    color: tnTextColor || configColors.text
  };

  return (
    <HubLayout headerStyle={style} props={audience} default_host={default_host}>
      <PreviewWalkthrough id={id} />
    </HubLayout>
  );
}

export default WalkthroughContainer;