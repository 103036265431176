import { createSlice } from '@reduxjs/toolkit';

export const teamSlice = createSlice({
  name: 'team',
  initialState: [],
  reducers: {
    setAdminUsers: (state, action) => {
      return action.payload;
    },
  }
});

export const { setAdminUsers } = teamSlice.actions;

export const getAdminUser = (state) => state.team;

export default teamSlice.reducer;

