import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { useSelector } from 'react-redux';
import { getDashboardData } from "../../../../../redux/dashboardSlice";
import { CaretDownIcon, CaretUpIcon } from "../../../../assets/Icons";

const DashboardDataCounter = () => {
  const { data, filterType } = useSelector(getDashboardData) || {};
  const {
    total_users_count,
    total_users_count_percentage,
    learned_hours,
    learned_hours_percentage,
    avg_completion_rate,
    avg_completion_rate_percentage,
    added_users_count,
    added_users_count_percentage,
  } = data || {};

  // Old code 
  const DATA_COUNT = 12;
  const labels = [];
  for (let i = 0; i < DATA_COUNT; ++i) {
    labels.push(i.toString());
  }
  const datapoints = [0, 20, 20, 60, 60, 120];
  const data1 = {
    labels: ["a", "b", "c", "d", "e", "f"],
    datasets: [
      {
        label: "Cubic interpolation",
        data: datapoints,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: false,
        title: {
          display: false,
        },
      },
      y: {
        grid: {
          color: "black",
        },
        border: {
          dash: [2, 4],
        },
        display: true,
        ticks: { display: false },
        title: {
          display: false,
          text: "Value",
        },
        suggestedMin: -10,
        suggestedMax: 200,
      },
    },
  };
  // Old code

  const stats = [
    { title: "Total Users", value: total_users_count, percentage: total_users_count_percentage },
    { title: "Hours Learned", value: learned_hours, percentage: learned_hours_percentage },
    { title: "Avg. Completion Rate", value: `${avg_completion_rate}%`, percentage: avg_completion_rate_percentage },
    { title: "Added Users", value: added_users_count, percentage: added_users_count_percentage },
  ];

  const renderBadge = (percentage) => {
    const isUp = percentage > 0;
    return (
      <div className={`badges ${isUp ? "up" : "down"}`}>
        <div className="icon-badges">{isUp ? <CaretUpIcon /> : <CaretDownIcon />}</div>
        <div className="text-badges">{percentage}%</div>
      </div>
    );
  };

  return (
    <div className="row-dashboard-data-counter custom-dashboard-data-counter mb-16">
      {stats.map(({ title, value, percentage }, index) => (
        <div key={index} className="item-dashboard-data-counter">
          <div className="dashboard-data-counter">
            <div className="header-dashboard-data-counter">
              <div className="item-header-dashboard-data-counter">
                <div className="tagline-dashboard-data-counter">{title}</div>
                <div className="number-dashboard-data-counter">{value}</div>
              </div>
            </div>
            {filterType !== "custom_date" &&
              <div className="flex-badges-dashboard-data">
                {renderBadge(percentage)}
                <div className="content-badges-dashboard-data">than last {filterType === "all_time" ? "month" : filterType}</div>
              </div>
            }
          </div>
        </div>
      ))}
      {/*<div className="user-chart-dashboard-data">
          {data && <Line data={data} options={options} />}
      </div>*/}
    </div>
  );
}

export default DashboardDataCounter;
