import React, { useEffect, useState } from 'react';
import { SearchIcon } from '../../assets/Icons';
import { useDispatch } from 'react-redux';
import {
  fetchMaterials,
  getMaterials,
  getCompanyDetails,
  setSearch,
  getSearch,
  setActiveTab
} from '../../../redux/hubSlice';
import { useSelector } from 'react-redux';
import { HUB_VIEW_ENDPOINTS } from '../utils/endpoints';
import sanitizeHtml from 'sanitize-html';

const Hub = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMaterials());
  }, []);

  const tabs = [
    {
      name: 'All',
      path: 'all',
      component: <div>All</div>
    },
    {
      name: 'Knowledge Bases',
      path: 'knowledge-bases',
      component: <div>Knowledge Bases</div>
    },
    {
      name: 'Paths',
      path: 'paths',
      component: <div>Paths</div>
    },
    {
      name: 'Courses',
      path: 'courses',
      component: <div>Courses</div>
    },
    {
      name: 'Product Walkthroughs',
      path: 'walkthrough',
      component: <div>Product Walkthroughs</div>
    },
    {
      name: 'Product Demos',
      path: 'product-demos',
      component: <div>Product Demos</div>
    },
    {
      name: 'Videos',
      path: 'videos',
      component: <div>Videos</div>
    }
  ];

  const company = useSelector((state) => getCompanyDetails(state));
  const materials = useSelector((state) => getMaterials(state));
  const [activeTab, setTab] = useState('all');
  const searchKeyword = useSelector((state) => getSearch(state));

  const setActive = (e) => {
    setTab(e);
    dispatch(setActiveTab(e));
  };

  const renderTabs = () => {
    return tabs.map((tab) => {
      const className = tab.path === activeTab ? 'active' : '';
      return (
        <li
          key={tab.path}
          className={className}
          onClick={() => setActive(tab.path)}
        >
          {tab.name}
        </li>
      );
    });
  };

  const showLearningMaterial = (slug) => {
    window.location.href = HUB_VIEW_ENDPOINTS.VIEW_MATERIAL(company.slug, slug);
  }

  const truncate = (str, n = 80) => {
    if (!str) return '';
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }

  const renderCards = () => {
    return materials.map((item, index) => {
      const backgroundUrl = item?.data[0]?.backgroundUrl;
      const defaultImageURL = backgroundUrl || require('../../assets/1.png');
      const backgroundImage = (item.material_type === "Walkthrough" && item.thumbnail_url == "/assets/not-found.png") ? defaultImageURL : item.thumbnail_url;
      const imageSrc = require('../../assets/1.png');
      return (
        <div className='un-hub__card' onClick={() => showLearningMaterial(item.slug)} key={index}>
          <div className='un-hub__card__image' style={{ backgroundImage: `url('${backgroundImage}')` }}>
            <h2>{item.name}</h2>
            <p>{company.name}</p>
            {false && (<div className='un-hub__card__image__favicon' />)}
          </div>
          <div className='un-hub__card__content'>
            <p className='un-hub__card__content__type'>{item.material_type}</p>
            <p className='un-hub__card__content__title'>{item.name}</p>
            <p className='un-hub__card__content__description'>{truncate(item.description)}</p>
          </div>
        </div>
      );
    });
  };

  const handleSearch = (e) => {
    dispatch(setSearch(e.target.value));
  }

  const highlightSearch = (text, keyword) => {
    const regex = new RegExp(keyword, 'gi');
    return sanitizeHtml(text.replace(regex, `<b>${keyword}</b>`));
  }

  const renderSearch = () => {
    return (
      <div className="un-hub__control__search">
        <input type="text" placeholder="Search" onChange={handleSearch} />
        <SearchIcon />
        {searchKeyword && (
          <div className='un-hub__control__search__suggestions'>
            <ul>
              {materials.map((item, index) => {
                return (
                  <li key={index}
                    dangerouslySetInnerHTML={{ __html: highlightSearch(item.name, searchKeyword) }}
                    onClick={() => showLearningMaterial(item.slug)}></li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="un-hub__legend">
        <div className="container">
          <h1>{company.name}</h1>
          <p>Hub content description</p>
        </div>
      </div>
      <div className="container">
        <div className="un-hub__content">
          <div className="un-hub__control">
            <div className="un-hub__control__tabs">
              <ul>{renderTabs()}</ul>
            </div>
            {renderSearch()}
          </div>
          <div className="un-hub__list">
            {renderCards()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hub;
