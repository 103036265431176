import React from 'react';
import { CheckMarkIcon } from '../../assets/Icons';

const Button = ({
  type,
  children,
  onClick,
  className,
  configColors,
  isLoading,
  isDone,
  disabled,
  style,
  ...props
}) => {
  const innerStyle = {
    '--primary-color': configColors.primary,
    '--background-color': configColors.background,
    '--text-color': configColors.text,
    '--border-color': configColors.border
  };

  style = { ...style, ...innerStyle };

  return (
    <button
      type={type}
      style={style}
      onClick={onClick}
      className={`btn ${className}`}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && (
        <img style={{ height: 20 }} src={require('../../assets/loader.svg')} />
      )}
      {isDone && <CheckMarkIcon />}
      {!isLoading && !isDone && children}
    </button>
  );
};

Button.defaultProps = {
  configColors: {
    primary: null,
    background: null,
    text: null,
    border: null
  },
  isLoading: false,
  isDone: false,
  disabled: false
};

export default Button;
