import React, { useEffect, useState } from 'react'
import TextInput from '../../atoms/text-input/TextInput'
import Button from '../../atoms/button/Button';
import { HUB_ENDPOINTS } from '../utils/endpoints';
import axios from 'axios';
import { getAuthToken } from '../utils/hub_client';

const EditProfileModal = ({ audience, modalClose }) => {

  const initialDetailsValue = {
    id: "",
    first_name: "",
    last_name: "",
    company_name: "",
    city: "",
    state: "",
    email: "",
  }
  const [userDetails, setUserDetails] = useState(initialDetailsValue);
  const [logoDetail, setLogoDetail] = useState(null);

  useEffect(() => {
    if (audience) {
      setUserDetails({
        ...audience,
      }
      );
    }
  }, [audience]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoDetail(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      const payload = {
        details: { ...userDetails },
      }
      if (logoDetail) {
        formData.append('avatar', logoDetail);
      }
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          formData.append(key, JSON.stringify(payload[key]));
        }
      }
      const response = await axios.put(HUB_ENDPOINTS.UPDATE_CURRENT_USER, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        },
      });
      if (response.status === 200) {
        alert(response.data.message);
        modalClose();
        window.location.reload();
      }
      else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div
      className="modal fade edit_profile_modal"
      id="editProfileModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel2"
    >
      <div
        className="modal-dialog"
        role="document"
        id="modal_dialog"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className='m-head'>
              <img src={audience?.avatar_url} />
              <p className='modal-heading'><b>My Account</b></p>
              <p>Adding your information helps personalize your experience</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="m-body">
                <TextInput
                  name={'first_name'}
                  placeholder={'First name'}
                  value={userDetails.first_name}
                  onChange={handleChange}
                />
                <TextInput
                  name={'last_name'}
                  placeholder={'Last name'}
                  value={userDetails.last_name}
                  onChange={handleChange}
                />
                <TextInput
                  name={'company_name'}
                  placeholder={'Company name'}
                  value={userDetails?.company_name}
                  onChange={handleChange}
                />
                <TextInput
                  name={'city'}
                  placeholder={'City'}
                  value={userDetails?.city}
                  onChange={handleChange}
                />
                <TextInput
                  name={'state'}
                  placeholder={'State'}
                  value={userDetails?.state}
                  onChange={handleChange}
                />
                <TextInput
                  name={'email'}
                  placeholder={'Email address'}
                  className='disabled'
                  disabled={true}
                  value={userDetails.email}
                  onChange={handleChange}
                />
                <label className="btn" style={{ paddingTop: 5, paddingBottom: 5, marginTop: 3 }}>
                  Update Profile Photo
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.gif"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </label>
                {logoDetail && <div>{logoDetail.name}</div>}
              </div>
              <hr />
              <div className="m-bottom">
                <Button
                  type="submit"
                  className="btn_submit"
                >
                  Update Profile
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProfileModal