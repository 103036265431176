import React, { useEffect, useRef } from 'react';
import Visitor1 from '../../../../assets/images/visitor-logo-1.png';
import ColumnHeader from '../../pages/audience/components/datatable/extra/ColumnHeader';
import { CONSTS } from '../../pages/utils/endpoints';

const handleOnImageLoadError = ({ currentTarget }) => {
	currentTarget.onerror = null;
	currentTarget.src = '/assets/customer_avatar.png';
}

// function IndeterminateCheckbox({ indeterminate, className = '', ...rest }) {
//   const ref = useRef(null);

//   useEffect(() => {
//     if (typeof indeterminate === 'boolean') {
//       ref.current.indeterminate = rest.checked && indeterminate;
//     }
//   }, [ref, indeterminate, rest.checked]);

//   return (
//     <input
//       type="checkbox"
//       ref={ref}
//       className={className + ' cursor-pointer data-checkbox'}
//       {...rest}
//     />
//   );
// }

const getLMColumns = (LMType) => {
  const columns = [
    // {
    //   id: 'checkbox',
    //   header: ({ table }) => (
    //     <div>
    //     {/* <IndeterminateCheckbox
    //       {...{
    //         checked: table.getIsAllRowsSelected(),
    //         indeterminate: table.getIsSomeRowsSelected(),
    //         onChange: table.getToggleAllRowsSelectedHandler(),
    //       }}
    //     /> */}
    //     </div>
    //   ),
    //   cell: ({ row }) => {
    //     return (
    //       <div>
    //         {/* <IndeterminateCheckbox
    //           {...{
    //             checked: row.getIsSelected(),
    //             disabled: !row.getCanSelect(),
    //             indeterminate: row.getIsSomeSelected(),
    //             onChange: row.getToggleSelectedHandler(),
    //           }}
    //         /> */}
    //       </div>
    //     );
    //   },
    // },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <ColumnHeader column={column} title="" />
      ),
      cell: ({ row }) => {
        const imageUrl = row.original?.imageUrl || Visitor1
        return (
          <div className="avatar-info-wrapper walkthrough-col-name">
            {!!row.depth && <div className="avatar-image-cover">
              <img className="avatar-image" src={imageUrl} alt="" onError={handleOnImageLoadError} />
            </div>}
            <div className="avatar-content-cover">
              <h3 className="avatar-name">{row.getValue('name')}</h3>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'progressPercentage',
      header: ({ column }) => <ColumnHeader column={column} title="Progress" />,
      cell: ({ row }) => {
        const percentage = row.getValue('progressPercentage');
        return (
          <div className="rating-data-table">
            <div className="counter-progress-bar">{percentage}%</div>
            <div className="rating-progress-bar">
              <div
                className="current-progress-bar"
                style={{ width: `${percentage}%` }}
              ></div>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'opens',
      header: ({ column }) => <ColumnHeader column={column} title="Opens" />,
      cell: ({ row }) => {
        return <div className="color-blue-dark">{row.getValue('opens')}</div>;
      },
    },
    // {
    //   accessorKey: 'completions',
    //   header: ({ column }) => (
    //     <ColumnHeader column={column} title="Completions" />
    //   ),
    //   cell: ({ row }) => {
    //     return (
    //       <div className="color-blue-dark">{row.getValue('completions')}</div>
    //     );
    //   },
    // },
    // {
    //   accessorKey: "uniqueVisits",
    //   header: ({ column }) => (
    //     <ColumnHeader column={column} title="Unique Visits" />
    //   ),
    //   cell: ({ row }) => {
    //     return (
    //       <div className="color-blue-dark">{row.getValue("uniqueVisits")}</div>
    //     );
    //   },
    // },
    // {
    //   id: 'toggle',
    //   header: ({ column }) => <ColumnHeader column={column} title="" />,
    //   cell: ({ row }) => {
    //     const isExpanded = row.getIsExpanded();

    //     const isExpandable = row.getCanExpand();

    //     return (
    //       <div className="data-table-cell fixed-cell">
    //         <div className="group-button-table-flex">
    //           {isExpandable ? (
    //             <div
    //               {...{
    //                 onClick: row.getToggleExpandedHandler(),
    //               }}
    //               className={`button-edit-table ${isExpanded ? 'active' : ''}`}
    //             >
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 width="25"
    //                 height="24"
    //                 viewBox="0 0 25 24"
    //                 fill="none"
    //               >
    //                 <path
    //                   d="M11.9989 15.7967L6.35597 9.44877C6.12893 9.1954 5.80403 9.03668 5.45241 9.00739C5.1008 8.97809 4.75113 9.0806 4.47997 9.29247C4.20882 9.50434 4.03828 9.8083 4.0057 10.1378C3.97312 10.4673 4.08116 10.7954 4.30615 11.0504L10.974 18.5507C11.0993 18.6914 11.2561 18.8045 11.4332 18.8821C11.6103 18.9598 11.8034 19 11.9989 19C12.1944 19 12.3875 18.9598 12.5646 18.8821C12.7417 18.8045 12.8985 18.6914 13.0238 18.5507L19.6917 11.0504C19.9177 10.7955 20.0266 10.467 19.9945 10.1369C19.9623 9.80683 19.7916 9.50222 19.52 9.28999C19.2484 9.07777 18.898 8.97529 18.5459 9.00506C18.1937 9.03483 17.8685 9.19442 17.6418 9.44877L11.9989 15.7967Z"
    //                   fill="currentColor"
    //                 ></path>
    //               </svg>
    //             </div>
    //           ) : (
    //             <div
    //               {...{
    //                 onClick: row.getToggleExpandedHandler(),
    //               }}
    //               className="button-edit-cancel-table"
    //             >
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 width="20"
    //                 height="19"
    //                 viewBox="0 0 20 19"
    //                 fill="none"
    //               >
    //                 <path
    //                   d="M11.6698 9.50012L15.7637 5.4062C15.9163 5.25056 16.0012 5.04101 16.0001 4.82308C15.999 4.60516 15.912 4.39648 15.7579 4.24239C15.6038 4.08829 15.3951 4.00123 15.1772 4.00013C14.9592 3.99903 14.7497 4.08398 14.594 4.23651L10.5001 8.33043L6.4062 4.23651C6.25056 4.08398 6.04101 3.99903 5.82308 4.00013C5.60516 4.00123 5.39648 4.08829 5.24239 4.24239C5.08829 4.39648 5.00123 4.60516 5.00013 4.82308C4.99903 5.04101 5.08398 5.25056 5.23651 5.4062L9.33043 9.50012L5.23651 13.594C5.08398 13.7497 4.99903 13.9592 5.00013 14.1772C5.00123 14.3951 5.08829 14.6038 5.24239 14.7579C5.39648 14.912 5.60516 14.999 5.82308 15.0001C6.04101 15.0012 6.25056 14.9163 6.4062 14.7637L10.5001 10.6698L14.594 14.7637C14.7497 14.9163 14.9592 15.0012 15.1772 15.0001C15.3951 14.999 15.6038 14.912 15.7579 14.7579C15.912 14.6038 15.999 14.3951 16.0001 14.1772C16.0012 13.9592 15.9163 13.7497 15.7637 13.594L11.6698 9.50012Z"
    //                   fill="currentColor"
    //                 />
    //               </svg>
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  let newColumns = [
    {
      accessorKey: 'currentStep',
      header: ({ column }) => (
        <ColumnHeader column={column} title="Current Step" />
      ),
      cell: ({ row }) => {
        return (
          <div className="color-blue-dark">{row.getValue('currentStep')}</div>
        );
      },
    },
    {
      accessorKey: 'stepStatus',
      
      header: ({ column }) => (
        <ColumnHeader column={column} title="Step Status" />
      ),
      cell: ({ row }) => {
        const type = row.getValue('stepStatus');

        const customClass =
          type === 'completed'
            ? 'text-completed-table'
            : type === 'in progress'
            ? 'text-progress-table'
            : 'text-not-started-table';

        return (
          <div className={`status-cell ${customClass}`}>
            <div>{row.getValue('stepStatus')}</div>
          </div>
        );
      },
    },
  ];

  if (LMType === CONSTS.DOCS) {
    newColumns = [
      {
        accessorKey: "lastPage",
        header: ({ column }) => (
          <ColumnHeader column={column} title="Last Page" />
        ),
        cell: ({ row }) => {
          return <div className="color-blue-dark">{row.getValue("lastPage")}</div>;
        },
      }
    ]
  }
  columns.splice(6, 0, ...newColumns);
  return columns;
};


export default getLMColumns;