import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DomainInput from './DomainInput';
import CopyBox from '../../../../atoms/copy-box/CopyBox';
import { CloseIcon, GlobeIcon } from '../../../../assets/Icons';
import { http_post, http_put } from '../../../utils/api_client';
import { API_ENDPOINTS } from '../../../utils/endpoints';

const DomainModalPopup = ({ isVisible, onClose, domainData, getAll }) => {
  const ipAddress = '44.233.12.82';
  const appDomain = 'usernurture.com';
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [name, setName] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const domainId = domainData?.id;

  useEffect(() => {
    if (domainId) {
      setName(domainData.name);
    }
  }, [domainId, domainData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const endpoint = domainId ? API_ENDPOINTS.UPDATE_DOMAIN(domainId) : API_ENDPOINTS.POST_DOMAIN;

    try {
      const response = domainId
        ? await http_put(endpoint, { domain_name: name })
        : await http_post(endpoint, { domain_name: name });

      if (response.status === (domainId ? 200 : 201)) { //200=OK, 201=CREATED
        alert(response.data.message);
        getAll();
      } else {
        alert("Something went wrong.");
      }
    } catch (error) {
      console.error('Error while saving domain data:', error);
      alert(error.response?.data?.error || "An error occurred.");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleDomainChange = (e) => {
    setName(e.value);
    setSubmitDisabled(!e.valid);
    setErrorMsg('');
  }

  useEffect(() => {
    setSubmitDisabled(!!errorMsg.length);
  }, [errorMsg]);

  return (
    <>
      <Modal show={isVisible} onHide={onClose} keyboard={false} backdrop="static" dialogClassName="custom-modal">
        <button className="close" type="button" onClick={onClose}>
          <CloseIcon />
        </button>
        <Modal.Body>
          <div className={'un-domain-instruction'}>
            <h4>{domainId ? `Edit & Connecting Your Domain and Subdomain` : `Connecting Your Domain and Subdomain`}</h4>
            <section>
              <p><b>Enter your domain/subdomain</b></p>
              <DomainInput onChange={handleDomainChange} errorMsg={errorMsg} domainName={name} />
            </section>
            <section>
              <p><b>Connect a Domain</b></p>
              <p>
                Copy the following IP address into the A record named <b>{appDomain}</b> for <b>{name}</b>. Set target
                to the IP address below:
              </p>
              <CopyBox icon={<GlobeIcon />}>{ipAddress}</CopyBox>
            </section>
            <section>
              <p><b>Connect a Subdomain</b></p>
              <p>
                Copy the following CNAME record into the CNAME record for <b>tester</b> for <b>{appDomain}</b>. If the
                record
                does not exist in your domain DNS settings, create it.
              </p>
              <CopyBox icon={<GlobeIcon />}>{appDomain}</CopyBox>
            </section>
          </div>
          <div className={'m-bottom un-domain-instruction__footer'}>
            <Button onClick={onClose} className={'ghost'}>Cancel</Button>
            <Button onClick={handleSubmit} disabled={submitDisabled || isLoading}>{domainId ? "Edit & Connect" : "Connect"}</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default DomainModalPopup;