import React, { useEffect, useState } from 'react';
import Button from '../../atoms/button/Button';
import { API_ENDPOINTS, ENDPOINTS } from '../../pages/utils/endpoints';
import TextInput from '../../atoms/text-input/TextInput';
import TextArea from '../../atoms/text-area/TextArea';
import DragDropUploader from '../../atoms/drag-drop-uploader/DragDropUploader';
import { http_get, http_put } from '../../pages/utils/api_client';
import { useDispatch, useSelector } from 'react-redux';
import { getMaterial, updateMaterial } from '../../../redux/materialSlice';
import { getCompany } from '../../../redux/companySlice';
import { Tooltip } from 'react-tooltip';
import Visibility from '../visibility/Visibility';
import DomainSelector from '../domain-selector/DomainSelector';
import { materialEditPath } from '../../../utils/endpoints';

const SettingsModal = () => {
  const dispatch = useDispatch();
  const material = useSelector((state) => getMaterial(state));
  const company = useSelector((state) => getCompany(state));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [domainName, setDomainName] = useState();
  const material_types = {
    "Paths": "paths",
    "Walkthrough": "walkthroughs",
    "Video": "videos",
    "Docs": "docs"
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsCreating(true);

    const data = {
      name: material.name,
      description: material.description,
      material_type: material.material_type.toLowerCase(),
      domain_id: material.domain_id,
      slug: material.slug
    };

    http_put(API_ENDPOINTS.LEARNING_MATERIAL(material.id), data)
      .then((res) => {
        window.location.href = materialEditPath({
          companySlug: company.slug,
          materialType: res.data.material_type,
          materialSlug: res.data.slug
        });
        hideModal();
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const hideModal = () => {
    $('#settingsModal').modal('hide');
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const label = e.target.label;
    dispatch(updateMaterial({ [name]: value }));
    setDomainName(`${label}\/`);
  };

  const saveToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    window.alert("Public url copied successfully.");
  }

  return (
    <div
      className="modal fade"
      id="settingsModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel2"
    >
      <div
        className="modal-dialog un-settings-modal"
        role="document"
        id="modal_dialog"
      >
        <div className="modal-content">
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M0 0L24 0 24 24 0 24z" />
                <path
                  fill="#657489"
                  d="M12 10.586L16.95 5.636 18.364 7.05 13.414 12 18.364 16.95 16.95 18.364 12 13.414 7.05 18.364 5.636 16.95 10.586 12 5.636 7.05 7.05 5.636z"
                />
              </g>
            </svg>
          </button>
          <div className="modal-body">
            <div className="m-head">
              <h3>Settings</h3>
            </div>
            <hr />
            <div className="m-body">
              <TextInput
                name={'name'}
                label={'Name'}
                placeholder={'Give this material a name'}
                value={material.name}
                onChange={handleChange}
              />
              <TextArea
                name={'description'}
                label={'Description'}
                placeholder={
                  'Tell the user what they will learn in this material'
                }
                value={material.description || ''}
                onChange={handleChange}
              />
              <DomainSelector
                name={'domain_id'}
                onChange={handleChange}
                value={material.domain_id}
              />
              <TextInput
                name={'slug'}
                label={'Content Public URL'}
                placeholder={'Enter the public URL for this content'}
                value={material.slug}
                onChange={handleChange}
                prefix={domainName}
                readOnly={true}
              />
              {/* <div className='public_url'>
                <TextInput
                  name={'slug'}
                  label={'Content Public URL'}
                  placeholder={'Enter the public URL for this content'}
                  value={`${company.slug}/${material_types[material.material_type]}/${material.slug}/published_view`}
                  onChange={handleChange}
                  prefix={domainName}
                  readOnly={true}
                />
                <div className="un-copy-box__icon" onClick={() => saveToClipboard(`${domainName}${company.slug}/${material_types[material.material_type]}/${material.slug}/published_view`)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M14.6667 3C15.6619 3 16.5086 3.63608 16.8223 4.52388L9.43068 4.52381C8.07218 4.52381 7.57955 4.66526 7.0829 4.93087C6.58625 5.19648 6.19648 5.58625 5.93087 6.0829C5.66526 6.57955 5.52381 7.07218 5.52381 8.43068L5.52388 16.5843C4.63608 16.2705 4 15.4238 4 14.4286V7.57143C4 5.0467 6.0467 3 8.57143 3H14.6667ZM17.7143 6.04762C18.9767 6.04762 20 7.07097 20 8.33333V18.2381C20 19.5005 18.9767 20.5238 17.7143 20.5238H9.33333C8.07097 20.5238 7.04762 19.5005 7.04762 18.2381V8.33333C7.04762 7.07097 8.07097 6.04762 9.33333 6.04762H17.7143ZM17.7143 7.57143H9.33333C8.91255 7.57143 8.57143 7.91255 8.57143 8.33333V18.2381C8.57143 18.6589 8.91255 19 9.33333 19H17.7143C18.1351 19 18.4762 18.6589 18.4762 18.2381V8.33333C18.4762 7.91255 18.1351 7.57143 17.7143 7.57143Z" fill="#64BBCD"></path></svg>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-6">
                  <DragDropUploader
                    name={'favicon_url'}
                    image_type="favicon"
                    label={'Logo'}
                    value={material.favicon_url && material.favicon_url !== '/assets/default_logo.svg' ? material.favicon_url : '' }
                    placeholder={'Enter the public URL for this content'}
                  />
                </div>
                <div className="col-md-6">
                  <DragDropUploader
                    name={'thumbnail'}
                    image_type="thumbnail"
                    label={'Content Thumbnail'}
                    value={material.thumbnail_url && material.thumbnail_url !== '/assets/not-found.png' ? material.thumbnail_url : '' }
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <p>User Access</p>
                  <Visibility />
                  <Tooltip
                    id="settings-tooltip"
                    place="bottom"
                    effect="solid"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="m-bottom">
              <Button className={'ghost blue'} onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                isLoading={isCreating}
                onClick={handleSubmit}
                style={{ marginLeft: '10px' }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
