import ProfileImage from 'components/assets/images/user-default-photo.png';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProfile,
  getProfile,
  setProfileData,
} from '../../../../../../redux/profileSlice';
import { API_ENDPOINTS } from '../../../../utils/endpoints';
import { http_delete } from '../../../../utils/api_client';

function EditProfileForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => getProfile(state));
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  useEffect(() => {
    if (data?.avatar_url && data?.avatar_url !== 'missing.png') {
      setSelectedPhoto(data?.avatar_url);
    }
  }, [data?.avatar_url]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    dispatch(setProfileData({ [name]: value }));
  };

  const handleUpdateClick = () => {
    fileInputRef.current.click();
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setSelectedPhoto(URL.createObjectURL(file));
    dispatch(setProfileData({ avatar: file }));
  };

  const handleDeletePhoto = () => {
    http_delete(API_ENDPOINTS.DELETE_PROFILE_AVATAR)
      .then((res) => {
        setSelectedPhoto(null);
        dispatch(fetchProfile());
      })
      .finally(() => {});
  };

  return (
    <div className="Edit-profile-form">
      <div className="flex-list-edit-input">
        <div className="item-list-edit-input">
          <div className="label-edit-input">
            <label className="text-label-profile">First name</label>
          </div>
          <div className="value-edit-input">
            <input
              className="edit-profile-input"
              name="first_name"
              type="text"
              value={data?.first_name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="item-list-edit-input">
          <div className="label-edit-input">
            <label className="text-label-profile">Last name</label>
          </div>
          <div className="value-edit-input">
            <input
              className="edit-profile-input"
              name="last_name"
              type="text"
              value={data?.last_name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="item-list-edit-input">
          <hr className="divider-edit-input"></hr>
        </div>
        <div className="item-list-edit-input align-top">
          <div className="label-edit-input">
            <div className="flex-content-profile">
              <label className="text-label-profile">Your photo</label>
              <p>This will be displayed on your profile.</p>
            </div>
          </div>
          <div className="value-edit-input">
            <div className="flex-edit-avatar-image">
              <div className="edit-avatar-image">
                <img
                  className="image-avatar"
                  src={selectedPhoto || ProfileImage}
                  alt="avatar"
                />
              </div>
              <div className="action-edit-avatar">
                <button
                  className="delete-avatar-image"
                  type="button"
                  onClick={handleDeletePhoto}
                >
                  Delete
                </button>
                <button
                  className="update-avatar-image"
                  type="button"
                  onClick={handleUpdateClick}
                >
                  Update
                </button>
                <input
                  ref={fileInputRef}
                  className="update-avatar-image"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handlePhotoChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="item-list-edit-input">
          <hr className="divider-edit-input"></hr>
        </div>
        <div className="item-list-edit-input">
          <div className="label-edit-input">
            <label className="text-label-profile">Job title</label>
          </div>
          <div className="value-edit-input">
            <input
              className="edit-profile-input"
              name="job_title"
              type="text"
              value={data?.job_title}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="item-list-edit-input">
          <div className="label-edit-input">
            <label className="text-label-profile">Company</label>
          </div>
          <div className="value-edit-input">
            <input
              className="edit-profile-input"
              name="company_name"
              type="text"
              value={data?.company_name}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {/* <div className="item-list-edit-input">
          <hr className="divider-edit-input"></hr>
        </div> */}
      </div>
      {/* <div className="wrapper-edit-password">
        <div className="head-edit-password">
          <h2 className="mb-4">Password</h2>
          <p>Please enter your current password to change your password.</p>
        </div>
        <div className="flex-edit-password">
          <div className="item-edit-password">
            <label className="text-current-password">Current password</label>
            <input
              className="edit-profile-input"
              type="password"
              placeholder="••••••••"
            />
          </div>
          <div className="item-edit-password">
            <label className="text-current-password">New password</label>
            <input
              className="edit-profile-input"
              type="password"
              placeholder="••••••••"
            />
            <div className="password-required-info">
              Your new password must be more than 8 characters.
            </div>
          </div>
          <div className="item-edit-password">
            <label className="text-current-password">
              Confirm new password
            </label>
            <input
              className="edit-profile-input"
              type="password"
              placeholder="••••••••"
            />
          </div>
        </div>
      </div>
      <div className="action-profile-button action-profile-page">
        <button className="button-action-cancel" type="button">
          Cancel
        </button>
        <button className="button-action-save" type="button">
          Update Password
        </button>
      </div> */}
    </div>
  );
}

export default EditProfileForm;
