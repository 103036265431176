import React, { useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Toolbar from "./Toolbar";
import Pagination from "./extra/Pagination";
import SatisfactionToolbar from "./SatisfactionToolbar";
import InteractionHistoryToolbar from "./InteractionHistoryToolbar";
import RemotePagination from "../../../pages/audience/components/datatable/extra/RemotePagination";
import Loader from "../../loader/Loader";

function getNestedPropertyValue(object, propertyPath) {
  const properties = propertyPath.split(".");
  let value = object;

  for (const prop of properties) {
    if (value && typeof value === "object") {
      value = value[prop];
    } else {
      return undefined;
    }
  }

  return value;
}

function DataTable({
  columns,
  data,
  isLoading,
  toolbarType = "",
  isPaginationEnabled = false,
  onPaginationChange = () => { },
  customTable = false,
}) {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [expanded, setExpanded] = useState()

  const nestedFilter = (row, ColumnId, value, addMeta) => {
    const name = row.original.learner.name;
    const email = row.original.learner.email;

    const userId = row.original.userId;

    if (name.toLowerCase().includes(value.toLowerCase())) {
      return true;
    }

    if (email.toLowerCase().includes(value.toLowerCase())) {
      return true;
    }

    if (userId.toLowerCase().includes(value.toLowerCase())) {
      return true;
    }

    return false;
  };

  const arrayFilter = (row, ColumnId, value, addMeta) => {
    const original = row.original;
    const propertiesToCheck = [
      "activityStatus",
      "learningGroup",
      "type",
      "rating.type",
      "status",
    ];

    for (const prop of propertiesToCheck) {
      let propertyValue = prop.includes(".")
        ? getNestedPropertyValue(original, prop)
        : original[prop];

      if (
        Array.isArray(propertyValue) &&
        propertyValue.some((item) => value.includes(item))
      ) {
        return true;
      } else if (
        typeof propertyValue === "string" &&
        value.includes(propertyValue)
      ) {
        return true;
      }
    }

    return false;
  };

  const table = useReactTable({
    data: data.records ? data.records : data,
    columns,
    filterFns: {
      nested: nestedFilter,
      customArray: arrayFilter,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true, //turn off client-side pagination
    pageCount: data.pagination ? data.pagination.total_pages : 0,
    onPaginationChange: onPaginationChange,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    // onExpandedChange: setExpanded,
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      // rowSelection,
      expanded: true,
      pagination: data.pagination ? data.pagination : null,
    },
    // enableRowSelection: true,
    // onRowSelectionChange: setRowSelection,
  });

  return (
    <>
      {toolbarType === "main" && <Toolbar table={table} />}
      {toolbarType === "satisfaction" && <SatisfactionToolbar table={table} />}
      {toolbarType === "history" && <InteractionHistoryToolbar table={table} />}
      <div
        className={`${toolbarType === "main" || customTable ? "data-table-wrapper" : ""
          }`}
      >
        <div
          className={`${toolbarType === "main" ? "page-padding" : ""
            }`}
        >
          <div
            className={`${toolbarType === "main" || customTable
              ? "data-table-cover"
              : "scroll-table"
              }`}
          >
            <table
              className={`
              ${customTable ? "access-data-table " : ""}
              ${toolbarType === "main" || customTable
                  ? "data-table "
                  : "table-avatar-model"
                } ${toolbarType === "satisfaction" &&
                "satisfaction-table-avatar-model"
                } ${toolbarType === "history" && "history-table-avatar-model"}`}
            >
              <thead
                className={
                  toolbarType === "main" || customTable
                    ? "data-table-head"
                    : "head-table-avatar-model"
                }
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    className={
                      toolbarType === "main" || customTable
                        ? "data-table-row"
                        : "tr-table-avatar-model"
                    }
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className={
                            toolbarType === "main" || customTable
                              ? "data-table-cell"
                              : "td-table-avatar-model"
                          }
                          key={header.id}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody
                className={
                  toolbarType === "main" || customTable
                    ? "data-table-body"
                    : "body-table-avatar-model"
                }
              >
                {isLoading ? (
                  <tr>
                    <td colSpan={1} >
                      <Loader />
                    </td>
                  </tr>
                )
                  :
                  table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => {
                      const visibleCells = row.getVisibleCells()
                      const isParent = !!visibleCells?.[0]?.row?.subRows.length;
                      const nameCell = visibleCells?.[0];
                      return (
                        <tr
                          className={
                            toolbarType === "main" || customTable
                              ? "data-table-row"
                              : "tr-table-avatar-model"
                          }
                          key={row.id}
                          data-state={row.getIsSelected() && "selected"}
                        >
                          {!isParent ?
                            visibleCells.map((cell) => (
                              <td
                                className={
                                  toolbarType === "main" || customTable
                                    ? "data-table-cell"
                                    : "td-table-avatar-model"
                                }
                                key={cell.id}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            )) :
                            <td
                              className={
                                toolbarType === "main" || customTable
                                  ? "data-table-cell"
                                  : "td-table-avatar-model"
                              }>
                              {flexRender(
                                nameCell.column.columnDef.cell,
                                nameCell.getContext()
                              )}
                            </td>
                          }
                        </tr>
                      )
                    })
                  ) : (
                    <tr
                      className={
                        toolbarType === "main" || customTable
                          ? "data-table-row"
                          : "tr-table-avatar-model"
                      }
                    >
                      <td
                        className={
                          toolbarType === "main" || customTable
                            ? "data-table-cell"
                            : "td-table-avatar-model"
                        }
                        colSpan={columns.length}
                      >
                        No results.
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isPaginationEnabled ?
        <RemotePagination table={table} pagination={data.pagination} />
        : <></>}
    </>
  );
}

export default DataTable;
