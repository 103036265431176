import React, { useEffect, useState } from 'react';
import { GlobeIcon, StorageIcon } from '../../../assets/Icons';
import Button from '../../../atoms/button/Button';
import { http_delete, http_get } from '../../utils/api_client';
import { API_ENDPOINTS } from '../../../pages/utils/endpoints';
import { getCompany } from '../../../../redux/companySlice';
import { useSelector } from 'react-redux';
import Loader from '../../../organisms/loader/Loader';
import DomainModalPopup from './components/DomainModalPopup';

const DomainPage = () => {
  const [domains, setDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const company = useSelector((state) => getCompany(state));

  useEffect(() => {
    getDomains();
  }, []);

  const toggleModal = (domain = null) => {
    setSelectedDomain(domain);
    setIsVisibleModal(!isVisibleModal);
  };

  const getDomains = async () => {
    try {
      setIsLoading(true);
      const response = await http_get(API_ENDPOINTS.GET_DOMAINS);
      if (response.status === 200) {
        setDomains(response.data);
      } else {
        alert("Failed to fetch domain data. Please try again.")
      }
    } catch (error) {
      console.log("Error :", error);
      alert("Failed to fetch domain data. Please try again.")
    } finally {
      setIsLoading(false);
    }
  }

  const handleManageSignIn = (id) => {
    const path = `/${company?.slug}/domains/${id}/domain_page_configuration`;
    window.location.pathname = path;
  }

  const handleDeleteDomain = async (domain) => {
    if (confirm(`Are you sure you want to delete this domain: ${domain?.name}?`)) {
      setIsLoading(true);
      try {
        const response = await http_delete(API_ENDPOINTS.DELETE_DOMAIN(domain?.id));
        if (response.status === 200) {
          alert(response.data.message);
          getDomains();
        }
        else {
          alert("Something went wrong.");
        }
      } catch (error) {
        console.error('Error while deleting domain data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const renderDomains = () => {
    return domains.map((domain) => {
      const statusClass = domain.status.toLowerCase();
      return (
        <tr key={domain.id}>
          <td>
            <div>
              <GlobeIcon />
              {domain.domain}
            </div>
          </td>
          <td>
            <div>
              <StorageIcon />
              {domain.subdomain}
            </div>
          </td>
          <td>
            <div className={`un-status__${statusClass}`}>{domain.status}</div>
          </td>
          <td>
            <div className='un-domain-options'>
              <button
                className="un-btn un-btn--primary"
                onClick={() => toggleModal(domain)}>
                Edit
              </button>
              <button
                className="un-btn un-btn--danger"
                onClick={() => handleDeleteDomain(domain)}>
                Delete
              </button>
              <button
                className="un-btn un-btn--primary"
                onClick={() => handleManageSignIn(domain.id)}>
                Manage Sign in Configuration
              </button>
            </div>
          </td>
        </tr >
      );
    });
  };

  return (
    <div className="un-settings">
      <div className="flex-profile-data">
        {isVisibleModal &&
          <DomainModalPopup isVisible={isVisibleModal} onClose={toggleModal} domainData={selectedDomain} getAll={getDomains} />
        }
        <section>
          <div className="section-heading">
            <h4>Add your Domain or Subdomain</h4>
            <Button className="ghost" onClick={() => toggleModal(null)}>
              Add Subdomain
            </Button>
          </div>
        </section>
        <table className="un-table">
          <thead>
            <tr>
              <th>Domain</th>
              <th>Subdomain</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={4}>
                  <Loader />
                </td>
              </tr>
            ) : (
              domains.length > 0 ? renderDomains() :
                <tr>
                  <td colSpan={4}>
                    No domain data.
                  </td>
                </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DomainPage;
