import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  setSteps, setConfig, setLoading, isCompleted, setCompleted
} from "../../../../redux/walkthroughPreviewSlice";
import SatisfactionScore from '../../../organisms/satisfaction-score/SatisfactionScore'
import { getCompanyDetails } from '../../../../redux/hubSlice';
import StepViewer from '../../paths/StepViewer';
import { HUB_ENDPOINTS } from "../../utils/endpoints";
import { http_get } from "../../utils/hub_client";
import EditPagePreview from '../../paths/EditPagePreview';
import PreviewHeader from '../../paths/PreviewHeader';
import { setMaterial } from '../../../../redux/materialSlice';

const PreviewPath = ({id, default_host}) => {
  const dispatch = useDispatch();
  const completed = useSelector((state) => isCompleted(state));
  const company = useSelector((state) => getCompanyDetails(state));
  const [openModal, setOpenModal] = useState(true);

  const submitScore = (score) => {
    setOpenModal(false);
    window.location.href = `/${company.slug}`;
  }

  const handleCloseModal = () => {
    dispatch(setCompleted(false));
  }

  useEffect(() => {
    dispatch(setLoading(true));
    const url = HUB_ENDPOINTS.GET_MATERIAL(id);
    http_get(url)
      .then((response) => {
        const config = response.data.config;
        const steps = response.data.data;
        const details = { config: config, ...response.data.details};
        dispatch(setSteps(steps));
        dispatch(setConfig(config));
        dispatch(setMaterial(details));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, []);

  useEffect(() => {
    if(completed && openModal){
      // $('#satisfactoryScore').modal({backdrop: 'static', keyboard: false});
    }
  }, [completed]);

  return (
    <>
      <SatisfactionScore onSubmitScore={submitScore} onCloseModal={handleCloseModal} />
      <div>
        <PreviewHeader default_host={default_host} />
        <StepViewer />
      </div>
    </>
  )
}

export default PreviewPath