import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../../redux/store';
import LMLineTab from '../../../organisms/line_tab/LMLineTab';
import { API_ENDPOINTS, CONSTS } from '../../utils/endpoints';
import ShareAccess from '../../../organisms/learning_materials/ShareAccess';
import LMTable from '../../../organisms/learning_materials/LMTable';
import { formatWalkthroughData, walkthroughsData } from './data';
import { http_get } from '../../utils/api_client';
import { usePagination } from '../../audience/IndexPage';
import debounce from 'lodash.debounce';
import { toast } from 'react-toastify';

const IndexPage = () => {
  const { onPaginationChange, page, limit, pagination } = usePagination();
  // const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState({ records: [], pagination });

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = debounce(async () => {
    setIsLoading(true);
    try {
      const response = await http_get(API_ENDPOINTS.GET_LEARNING_MATERIALS_AUDIENCE, {
        category: CONSTS.WALKTHROUGH,
        page,
        limit,
      })

      const { data } = response;
      setTableData({
        records: data.records,
        pagination: { ...pagination, ...data.pagination, pageSize: data.pagination.limit }
      });
      // setTotalCount(data.total_count);
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, 500);

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const groupedData = formatWalkthroughData(tableData.records)

  return (
    <Provider store={store}>

      <div className="un-page-container__learning-materials">
        <div className="header">
          <div className="title">
            <h1>Walkthroughs</h1>

          </div>
        </div>
        <LMLineTab defaultTab={1} tabName={CONSTS.WALKTHROUGHS} />
        {/* <ShareAccess /> */}
        <LMTable
          LMType={CONSTS.WALKTHROUGHS}
          data={{
            ...tableData,
            records: groupedData
          }}
          onPaginationChange={onPaginationChange}
          isLoading={isLoading} />
      </div>
    </Provider>
  );
};

export default IndexPage;
