import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { useSelector } from 'react-redux';
import { getDashboardData } from "../../../../../redux/dashboardSlice";
import moment from "moment/moment";

const FILTER_TYPE_LABELS = {
  week: Array.from({ length: 7 }, (_, i) => moment().startOf('isoWeek').add(i, 'days').format('ddd')),
  month: Array.from({ length: moment().daysInMonth() }, (_, i) => `${i + 1}`),
  all_time: Array.from({ length: 12 }, (_, i) => moment().month(i).format('MMM')),
  year: Array.from({ length: 12 }, (_, i) => moment().month(i).format('MMM')),
};

const options = {
  elements: { point: { radius: 0, }, },
  scales: {
    y: {
      grid: { color: "rgba(227, 227, 227, 1)", },
      border: { dash: [2, 4], },
      display: true,
      beginAtZero: true,
      stacked: true,
      title: { display: false, text: "Statistics", },
    },
    x: { grid: { display: false } },
  },
  layout: { padding: 20 },
  plugins: { legend: { display: false } },
};

const EngagedUsers = () => {
  const { data, filterType } = useSelector(getDashboardData) || {};
  const graph_data = data?.graph_data || {};

  const labels = useMemo(() => {
    if (filterType === 'custom_date') return Object.keys(graph_data).map(range => moment(range).format('D MMM YYYY'));
    return FILTER_TYPE_LABELS[filterType] || Object.keys(graph_data);
  }, [filterType, graph_data]);

  const datasetData = useMemo(() => {
    if (!graph_data) return [];
    if (filterType === "custom_date") return Object.values(graph_data);
    return labels.map(label => graph_data[label] ?? 0);
  }, [filterType, graph_data, labels]);

  const graphData = {
    labels,
    datasets: [
      {
        label: 'Page Views',
        data: datasetData,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          var gradient = ctx.createLinearGradient(0, 0, 0, 700); // Dimensions of the color rectangle
          gradient.addColorStop(1, 'rgba(100, 187, 205, 0)'); // First color
          gradient.addColorStop(0, 'rgba(100, 187, 205, 0.5)'); // Second color
          return gradient;
        },
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: true,
      },
    ],
  };

  return (
    <div className="wrapper-engaged-user mb-16">
      <div className="engaged-user">
        <div className="header-engaged-user">
          <div className="item-header-engaged-user">
            <h3 className="title-size-18">Engaged Users</h3>
            <p className="content-size-14">
              These users have accessed your learning content during the
              outlined time period
            </p>
          </div>
        </div>
        <div className="big-chart-engaged-user">
          {graphData && <Line data={graphData} options={options} />}
        </div>
      </div>
    </div>
  );
}

export default EngagedUsers;
