import React, { useEffect, useMemo, useState } from "react";
import MultiCombobox from "../MultiCombobox";
import debounce from 'lodash.debounce';
import axios from "axios";
import { AUDIENCE_GET_FILES_DETAILS } from "../../../../endpoints";
import { AuthContext } from "../../../../contexts";
import Loader from "../../../../organisms/loader/Loader";
import { formatDateDifference, materialName, renderFileIcon } from "../../../utils/tools";

function Files({ profile }) {
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { token } = React.useContext(AuthContext);

  const fetchActivityData = debounce(async () => {
    try {
      const options = {
        url: `${AUDIENCE_GET_FILES_DETAILS(profile?.id)}`,
        headers: {
          Authorization: token
        }
      };
      const response = await axios.request(options);
      setFileData(response.data.records);
    } catch (error) {
      console.error("Error fetching file details:", error);
      alert("Failed to fetch file details. Please try again.");
    } finally {
      setLoading(false);
    }
  }, 500)

  useEffect(() => {
    fetchActivityData();
  }, []);

  const handleSearch = (e) => setSearchTerm(e.target.value);

  const filteredFiles = useMemo(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return fileData.filter(file =>
      file.file_name.toLowerCase().includes(lowercasedSearchTerm)
    );
  }, [fileData, searchTerm]);

  const handleDownloadFile = (data) => {
    const link = document.createElement('a');
    link.href = data.file_url;
    link.download = data.file_name;
    link.click();
  };

  const getMaterialName = (name) => materialName[name] || "";

  return (
    <div className="responses files">
      <h5 className="title">Files Uploaded</h5>
      <div className="option">
        <div className="search">
          <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.3212 21.4271C12.3212 26.3471 16.3097 30.3356 21.2298 30.3356C23.5248 30.3356 25.6171 29.4678 27.1965 28.0424C27.2557 27.9017 27.3426 27.7698 27.4573 27.6552C27.5719 27.5405 27.7038 27.4536 27.8446 27.3943C29.2703 25.8149 30.1384 23.7224 30.1384 21.4271C30.1384 16.507 26.1499 12.5185 21.2298 12.5185C16.3097 12.5185 12.3212 16.507 12.3212 21.4271ZM28.3419 30.2578C26.3969 31.8263 23.923 32.7653 21.2298 32.7653C14.9679 32.7653 9.89161 27.689 9.89161 21.4271C9.89161 15.1652 14.9679 10.0889 21.2298 10.0889C27.4917 10.0889 32.568 15.1652 32.568 21.4271C32.568 24.1206 31.6288 26.5947 30.06 28.5399L34.8444 33.3243C35.3188 33.7987 35.3188 34.5679 34.8444 35.0423C34.37 35.5167 33.6008 35.5167 33.1264 35.0423L28.3419 30.2578Z"
              fill="#64748B"
            />
          </svg>
          <input
            placeholder="Search File"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        {/* <MultiCombobox
          options={[{ value: "", label: "" }]}
          filters={[]}
          placeholder="Modified"
          isSelectAllEnabled
        /> */}
      </div>
      {loading ? <Loader /> :
        <>
          <div className="files-content">
            {filteredFiles.length === 0 ?
              <div className="item">No File Found.</div>
              :
              filteredFiles.map((data, index) => {
                return (
                  <>
                    <div key={index} className="item">
                      <div className="left">
                        <div className="file-icon">
                          <i className={renderFileIcon(data?.file_type) || 'fa fa-file-o'} aria-hidden="true"></i>
                        </div>
                        <div className="title">
                          <h5>{data?.file_name}</h5>
                          <p>{getMaterialName(data?.material_type)} : {data?.material_name}</p>
                          <p>{formatDateDifference(data.updated_at)}</p>
                        </div>
                      </div>
                      <div className="right">
                        <div
                          className="outline"
                          onClick={() => handleDownloadFile(data)}
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.17353 0.941751C5.42189 0.274118 6.6498 0.0666504 9.22081 0.0666504H12.2874C13.1016 0.0666504 13.7616 0.726652 13.7616 1.54081C13.7616 2.35496 13.1016 3.01496 12.2874 3.01496H9.22081C6.78572 3.01496 6.15686 3.22452 5.56395 3.54161C5.01385 3.83581 4.59094 4.25872 4.29674 4.80882C3.97965 5.40173 3.77009 6.03059 3.77009 8.46568V20.8231C3.77009 23.2582 3.97965 23.8871 4.29674 24.48C4.59094 25.0301 5.01385 25.453 5.56395 25.7472C6.15686 26.0643 6.78572 26.2739 9.22081 26.2739H21.5783C24.0134 26.2739 24.6422 26.0643 25.2351 25.7472C25.7852 25.453 26.2081 25.0301 26.5023 24.48C26.8194 23.8871 27.029 23.2582 27.029 20.8231V17.7565C27.029 16.9423 27.689 16.2823 28.5031 16.2823C29.3173 16.2823 29.9773 16.9423 29.9773 17.7565V20.8231C29.9773 23.3941 29.7698 24.622 29.1022 25.8704C28.5332 26.9343 27.6894 27.7781 26.6255 28.3471C25.3772 29.0147 24.1493 29.2222 21.5783 29.2222H9.22081C6.6498 29.2222 5.42189 29.0147 4.17353 28.3471C3.10963 27.7781 2.26586 26.9343 1.69688 25.8704C1.02925 24.622 0.821777 23.3941 0.821777 20.8231V8.46568C0.821777 5.89467 1.02925 4.66677 1.69688 3.4184C2.26586 2.3545 3.10963 1.51073 4.17353 0.941751Z"
                              fill="#50B1C9"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M18.6754 1.54081C18.6754 0.726652 19.3354 0.0666504 20.1496 0.0666504H28.5032C29.3173 0.0666504 29.9773 0.726652 29.9773 1.54081V9.89435C29.9773 10.7085 29.3173 11.3685 28.5032 11.3685C27.689 11.3685 27.029 10.7085 27.029 9.89435V5.09973L16.2782 15.8506C15.7025 16.4263 14.7691 16.4263 14.1934 15.8506C13.6177 15.2749 13.6177 14.3415 14.1934 13.7658L24.9443 3.01496H20.1496C19.3354 3.01496 18.6754 2.35496 18.6754 1.54081Z"
                              fill="#50B1C9"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </>
      }
    </div>
  );
}

export default Files;
