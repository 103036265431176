import React, { useState, useRef } from 'react';
import { UploadIcon } from '../../assets/Icons';
import { getMaterial, setMaterial, updateMaterial } from '../../../redux/materialSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getLayerConfig, updateLayerConfig, updateMainConfig } from '../../../redux/stepsSlice';

const DragDropUploader = ({ label, value, name, image_type, acceptedExtensions }) => {
  const acceptedExtensionString = acceptedExtensions.join(', ').toUpperCase();
  const [filePreview, setFilePreview] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false); // Track whether a file is being dragged over
  const material = useSelector((state) => getMaterial(state));
  const dispatch = useDispatch();
  const layerConfig = useSelector((state) => getLayerConfig(state));

  const fileInputRef = useRef(null);

  const updateConfig = (sectionId, titleId, newValue) => {
    const data = {
      sectionId: sectionId,
      titleId: titleId,
      value: newValue
    };
    if (layerConfig.length > 0) {
      dispatch(updateLayerConfig(data));
    } else {
      dispatch(updateMainConfig(data));
    }
  };

  const handleFileSelect = async(e) => {
    const input = e.target;
    const file = input.files[0];

    if (file) {
      const reader = new FileReader();
      const response = await uploadImage(file);
      if (response) {
        dispatch(updateMaterial({[name]: response.image_url}));
        switch (image_type) {
          case 'favicon':
            updateConfig('phc', 'phcHeaderLogo', response.image_url);
            alert(`Logo added successfully.`);
            break;
          case 'thumbnail':
            dispatch(updateMaterial({'thumbnail_url': response.image_url}))
            alert(`Thumbnail added successfully.`);
            break;
          default:
            alert(`${name.charAt(0).toUpperCase() + name.slice(1)} added successfully.`);
            break;
        }
      }
      else {
        alert('Something went wrong.');
      }

      reader.onload = (e) => {
        // setFilePreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleOuterDivClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true); // Add class when file is dragged over
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false); // Remove class when user moves the file out
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDraggingOver(false); // Remove class when file is dropped
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const file = droppedFiles[0];
      const response = await uploadImage(droppedFiles[0]);
      if (response) {
        dispatch(updateMaterial({[name]: response.image_url}));
        switch (image_type) {
          case 'favicon':
            updateConfig('phc', 'phcHeaderLogo', response.image_url);
            alert(`Logo added successfully.`);
            break;
          case 'thumbnail':
            dispatch(updateMaterial({'thumbnail_url': response.image_url}))
            alert(`Thumbnail added successfully.`);
            break;
          default:
            alert(`${name.charAt(0).toUpperCase() + name.slice(1)} added successfully.`);
            break;
        }
      }
      else {
        alert('Something went wrong.');
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        // setFilePreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('image_type', image_type)
    const url = `/api/v1/uploads/learning_material/${material.slug}`;
    const response = await axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .finally(() => {        
      });
    return response.data;
  };

  const handleRemoveImage = () => {
    setFilePreview(null); // Clear the file preview when the remove button is clicked
  };

  const divClassName = `un-drag-drop-uploader ${
    isDraggingOver ? 'drag-over' : ''
  }`;

  const filePreviewText = filePreview ? (
    <div>
      <button onClick={handleRemoveImage} className="remove-button">
        x
      </button>
      <img src={filePreview} alt="File Preview" className="preview-image" />
    </div>
  ) : (
    (!value || value === 'usernurture-logo-dark.png') && (
      <div onClick={handleOuterDivClick}>
        Click to upload or drag and drop
        <br />
        {acceptedExtensionString}
      </div>
    )
  );

  return (
    <div
      className={divClassName}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <p>{label}</p>
      <div className="drag-drop-box">
        {filePreview || (!value) && (
          <div className="drag-icon" onClick={handleOuterDivClick}>
            <UploadIcon />
          </div>
        )}
        {(value && value !== 'usernurture-logo-dark.png') && (
          <img src={value} onClick={handleOuterDivClick} alt="File Preview" className="preview-image" />
        )}
        {filePreviewText}
      </div>
      <input
        type="file"
        name={name}
        ref={fileInputRef}
        onChange={handleFileSelect}
        style={{ display: 'none' }}
      />
    </div>
  );
};

DragDropUploader.defaultProps = {
  name: '',
  label: 'Logo',
  value: '',
  acceptedExtensions: ['jpg', 'jpeg', 'png', 'gif']
};

export default DragDropUploader;
