import { createSlice } from '@reduxjs/toolkit';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    data: {},
    filterType: 'all_time'
  },
  reducers: {
    setDashboardData: (state, action) => {
      state.data = action.payload.data;
      state.filterType = action.payload.filterType;
    },
  }
});

export const { setDashboardData } = dashboardSlice.actions;

export const getDashboardData = (state) => state.dashboard;

export default dashboardSlice.reducer;

