import React, { useState, useEffect } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import { useSelector } from 'react-redux';
import { RenderPreviewSquare } from '../tools/Square';
import { RenderPreviewText } from '../tools/TextTool';
import { RenderPreviewHotspot } from '../tools/HotSpot';
import { RenderPreviewTextInput } from '../tools/TextInput';
import { getActiveStep } from '../../../redux/toolsSlice';
import { getValue } from '../../pages/utils/tools';
import { RenderPreviewLine } from '../tools/Line';
import { getEmbedActiveStep } from '../../../redux/walkthroughEmbedPreviewSlice';

class URLImage extends React.Component {
  state = {
    image: null,
    width: 0,
    height: 0
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }
  loadImage() {
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    const height = (this.image.height / this.image.width) * 1024;
    const width = this.image.width > 1024 ? 1024 : this.image.width;
    this.setState({
      image: this.image,
      width: 1024,
      height: height
    });

    const stage = this.imageNode.getStage();
    stage.width(width);
    stage.height(height);
    stage.batchDraw();
  };

  render() {
    return (
      <Image
        x={this.props.x}
        y={this.props.y}
        image={this.state.image}
        ref={(node) => {
          this.imageNode = node;
        }}
        width={this.state.width}
        height={this.state.height}
      />
    );
  }
}

const DesignCanvas = ({onChange, configColors, embed = false, id, step }) => {
  const [stageSize, setStageSize] = useState({ width: 1024, height: 768 });
  const [previewScale, setPreviewScale] = useState(1);
  const layers = step?.layers;
  const activeBackgroundURL = step?.backgroundUrl;

  useEffect(() => {
    const stageContainer = document.querySelector('.un-design-canvas');
    const editorOption = document.querySelector('.un-editor-options');
    const stepSidebar = document.querySelector('.un-steps-sidebar');

    const updateStageSize = () => {
      const widthSize =
        window.innerWidth -
        ((editorOption ? editorOption.offsetWidth : 0) +
          (stepSidebar ? stepSidebar.offsetWidth : 0) +
          16);
      if (stageContainer) {
        setStageSize({
          width: widthSize > 1024 ? 1024 : widthSize,
          height: window.innerHeight > 640 ? 640 : window.innerHeight
        });
      }
      const wrapper = document.getElementsByClassName('un-preview-canvas')[0];
      const editor = document.getElementsByClassName('un-design-canvas--stage')?.[0];
      setPreviewScale(wrapper?.clientWidth/(editor?.scrollWidth + 150));
      const previewWrapper = document.querySelector('.preview-wrapper');
      if(previewWrapper && embed){
        previewWrapper.style.height = `${previewWrapper.clientHeight * (wrapper?.clientWidth/(editor?.scrollWidth + 150))}px`;
      }
    };

    window.addEventListener('resize', updateStageSize);
    updateStageSize();

    return () => {
      window.removeEventListener('resize', updateStageSize);
    };
  }, []);

  useEffect(() => {

  }, [step])

  const renderLayers = () => {
    if (!layers) return null;
    return layers.map((layer, index) => {
      switch (layer.type) {
        case 'image':
          return (
            <URLImage
              src={layer.src}
              x={layer.x}
              y={layer.y}
              width={layer.width}
              height={layer.height}
              key={layer.id}
            />
          );
        case 'rect':
          return (
            <RenderPreviewSquare stepId={step.id} layer={layer} key={index} />
          );
        case 'text':
          return (
            <RenderPreviewText stepId={step.id} layer={layer} key={index} />
          );
        case 'hotspot':
          return (
            <RenderPreviewHotspot stepId={step.id} layer={layer} key={index} embed={embed} step={step} id={id} onChange={onChange} />
          );
        case 'textInput':
          return (
            <RenderPreviewTextInput
              stepId={step.id}
              layer={layer}
              key={index}
            />
          );
        case 'line':
          return(
            <RenderPreviewLine
              stepId={step.id}
              layer={layer}
              key={index}
            />
          )
        default:
          return null;
      }
    });
  };

  return (
    <div className="un-preview-canvas">
      {activeBackgroundURL && (
        <Stage
          className="un-design-canvas--stage"
          width={stageSize.width}
          height={stageSize.height}
          style={{ backgroundColor: '#fff', transform: `scale(${previewScale})`, transformOrigin: 'top' }}
        >
          <Layer>
            <URLImage src={activeBackgroundURL} x={0} y={0} width={1024} />
            {renderLayers()}
          </Layer>
        </Stage>
      )}
    </div>
  );
};

export default DesignCanvas;
