import React, { useState } from "react";
import Attributes from "./attributes";
import Activity from "./activity";
import Responses from "./responses";
import Files from "./files";
import Header from "./header";
import "./modal.css";

const options = [
  {
    id: "attributes",
    title: "Attributes",
    component: Attributes,
  },
  {
    id: "activity",
    title: "Activity",
    component: Activity,
  },
  {
    id: "responses",
    title: "Responses",
    component: Responses,
  },
  {
    id: "files",
    title: "Files",
    component: Files,
  },
];

// pass the id or the whole profile
function AudienceModal({ isActive, setIsActive, profile }) {
  const [activeId, setActiveId] = useState(options[0].id);

  const toggleSection = (id) => {
    const option = options.find((e) => e.id === id);
    setActiveId(option.id);
  };

  const ActiveSection = options.find((e) => e.id === activeId).component;

  return (
    <div className="audience-modal">
      <div className={`model-avatar-wrapper ${isActive ? "active" : ""}`}>
        <div className="model-avatar-data">
          <Header profile={profile} setIsActive={setIsActive} />

          <div className="tab-avatar-model">
            <div className="menu-tab-avatar-model">
              {options.map((option) => {
                return (
                  <div
                    key={option.id}
                    className={`item-menu-tab-avatar ${option.id === activeId ? "active" : ""
                      }`}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      onClick={() => toggleSection(option.id)}
                      className={`menu-tab-avatar ${option.id === activeId ? "active" : ""
                        }`}
                    >
                      {option.title}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="tab-container-avatar-model">
              {<ActiveSection profile={profile} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AudienceModal;
