export const formatWalkthroughData = (data = []) => {
  // Grouping data by material_name
  const groupedData = data.reduce((acc, item) => {
    const materialName = item.material_name;

    // Check if the material already exists in the accumulator
    if (!acc[materialName]) {
      acc[materialName] = {
        name: materialName,
        subRows: []
      };
    }

    // Adding subRow for each individual entry
    acc[materialName].subRows.push({
      name: item.name,
      imageUrl: item.imageUrl,
      progressPercentage: item.progress || 0,
      opens: item.opens || 0,
      currentStep: item.current_step || "",
      stepStatus: item.status
    });

    return acc;
  }, {});

  // Convert the grouped data from object to array
  return Object.values(groupedData);
}