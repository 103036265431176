import React, { useState } from "react";
import Details from "./Details";
import InteractionHistory from "./InteractionHistory";
import FilesUploaded from "./FilesUploaded";
import Notes from "./Notes";
import SatisfactionScore from "./SatisfactionScore";
import HeaderData from "./HeaderData";
import HeaderExtraData from "./HeaderExtraData";

const options = [
  {
    id: "details",
    title: "Details",
    component: Details,
  },
  {
    id: "interaction-history",
    title: "Interaction History",
    component: InteractionHistory,
  },
  {
    id: "files-uploaded",
    title: "Files Uploaded",
    component: FilesUploaded,
  },
  {
    id: "notes",
    title: "Notes",
    component: Notes,
  },
  {
    id: "satisfaction-score",
    title: "Satisfaction Score",
    component: SatisfactionScore,
  },
];

// pass the id or the whole profile
function AvatarModal({ isActive, setIsActive, profile }) {
  const [activeId, setActiveId] = useState(options[0].id);

  const toggleSection = (id) => {
    const option = options.find((e) => e.id === id);
    setActiveId(option.id);
  };

  const ActiveSection = options.find((e) => e.id === activeId).component;

  return (
    <div
      className={`model-avatar-wrapper ${isActive ? "active" : ""}`}
    >
      <div className="model-avatar-data">
        <HeaderData profile={profile} setIsActive={setIsActive} />
        <HeaderExtraData profile={profile} />

        <div className="tab-avatar-model">
          <div className="menu-tab-avatar-model">
            {options.map((option) => {
              return (
                <div
                  key={option.id}
                  className={`item-menu-tab-avatar ${option.id === activeId ? "active" : ""
                    }`}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    onClick={() => toggleSection(option.id)}
                    className={`menu-tab-avatar ${option.id === activeId ? "active" : ""
                      }`}
                  >
                    {option.title}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="tab-container-avatar-model">
            {<ActiveSection profile={profile} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvatarModal;
