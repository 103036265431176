import React, { useCallback, useMemo } from 'react';
import ProfileImage from 'components/assets/images/user-default-photo.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, getProfile } from '../../../../../../redux/profileSlice';
import { API_ENDPOINTS } from '../../../../utils/endpoints';
import { http_put } from '../../../../utils/api_client';

const ProfileAvatar = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getProfile(state));

  const handleFetchProfile = useCallback(() => {
    dispatch(fetchProfile());
  }, [dispatch, fetchProfile]);

  const handleSaveClick = useCallback(async () => {
    try {
      const formData = new FormData();
      data?.avatar && formData.append('avatar', data?.avatar);
      data?.first_name && formData.append('first_name', data?.first_name);
      data?.last_name && formData.append('last_name', data?.last_name);
      data?.job_title && formData.append('job_title', data?.job_title);
      data?.company_name && formData.append('company_name', data?.company_name);

      await http_put(API_ENDPOINTS.PUT_PROFILE, formData);
      alert("Profile data updated successfully.");
      handleFetchProfile();

    } catch {
      console.error('Error fetching profile data:', error);
    }
  }, [data]);

  const avatarUrl = useMemo(
    () =>
      data?.avatar_url && data.avatar_url !== 'missing.png'
        ? data.avatar_url
        : ProfileImage,
    [data?.avatar_url]
  );

  return (
    <div className="profile-avatar-data">
      <div className="image-profile-avatar">
        <img src={avatarUrl} alt="avatar" />
      </div>
      <div className="content-profile-avatar">
        <div className="left-content-profile-avatar">
          {data?.first_name && data?.last_name && (
            <h2 className="mb-4">{`${data.first_name} ${data.last_name}`}</h2>
          )}
          {data?.job_title && data?.company_name && (
            <p>
              {data.job_title} @{' '}
              <span className="text-weight-semibold">{data.company_name}</span>
            </p>
          )}
        </div>
        <div className="right-content-profile-avatar">
          <div className="action-profile-button">
            <button
              className="button-action-cancel"
              type="button"
              onClick={handleFetchProfile}
            >
              Cancel
            </button>
            <button
              className="button-action-save"
              type="button"
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileAvatar;
