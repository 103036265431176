import React, { useEffect } from 'react';
import Button from '../../atoms/button/Button';
import { BackIcon, ForwardIcon } from '../../assets/Icons';
import PreviewCanvas from '../preview-canvas/PreviewCanvas';
import { useDispatch } from 'react-redux';
import { setActiveStep } from '../../../redux/toolsSlice';
import { getValue } from '../../pages/utils/tools';
import {
  setNextIndex,
  setPrevIndex
} from '../../../redux/walkthroughPreviewSlice';
import { RenderEscapedString } from '../../pages/utils/tools';
import { setEmbedActiveStep, setEmbedNextIndex, setEmbedPrevIndex } from '../../../redux/walkthroughEmbedPreviewSlice';

const Screen = ({ onChange, onActionBtn, configColors, steps, index, embed, id }) => {
  const dispatch = useDispatch();
  const currPage = steps[index] || {
    name: '',
    title: '',
    description: '',
    backgroundUrl: require('../../assets/loader.svg')
  };
  const { name, title, description } = currPage;

  // colors
  const tnBGColor = getValue(configColors, 'tn', 'tnBGColor');
  const tnTextColor = getValue(configColors, 'tn', 'tnTextColor');
  const mcaBGColor = getValue(configColors, 'mca', 'mcaBgColor');
  const mcaStepHeadlineFont = getValue(
    configColors,
    'mca',
    'mcaStepHeadlineFont'
  );
  const mcaStepHeadlineFontSize = getValue(
    configColors,
    'mca',
    'mcaStepHeadlineFontSize'
  );
  const mcaStepHeadlineColor = getValue(
    configColors,
    'mca',
    'mcaStepHeadlineColor'
  );
  const mcaStepDescriptionFont = getValue(
    configColors,
    'mca',
    'mcaStepDescriptionFont'
  );
  const mcaStepDescriptionFontSize = getValue(
    configColors,
    'mca',
    'mcaStepDescriptionFontSize'
  );
  const mcaStepDescriptionColor = getValue(
    configColors,
    'mca',
    'mcaStepDescriptionColor'
  );

  const mcaInstructionFont = getValue(
    configColors,
    'mca',
    'mcaInstructionFont'
  );

  const mcaInstructionFontSize = getValue(
    configColors,
    'mca',
    'mcaInstructionFontSize'
  );

  const mcaInstructionColor = getValue(
    configColors,
    'mca',
    'mcaInstructionColor'
  );
  const eaBGColor = getValue(configColors, 'ea', 'eaBGColor');
  const eaTextColor = getValue(configColors, 'ea', 'eaTextColor');

  const colors = {
    primary: tnBGColor || configColors.primary,
    background: tnBGColor || configColors.background,
    text: tnTextColor || configColors.text,
    border: tnBGColor || configColors.border
  };

  useEffect(() => {
    // dispatch(embed ? setEmbedActiveStep({data: currPage, id: id}) : setActiveStep(currPage));
  }, [currPage]);

  const prevPage = () => {
    // dispatch(embed ? setEmbedPrevIndex(id) : setPrevIndex());
    onActionBtn(index - 1);
  };

  const nextPage = () => {
    // dispatch(embed ? setEmbedNextIndex(id) : setNextIndex());
    onActionBtn(index + 1);
  };

  const renderActionBtn = () => {
    return (
      <div className="action-btn mb-32">
        <Button
          className="btn primary ghost"
          onClick={prevPage}
          configColors={colors}
        // disabled={index === 0}
        >
          <BackIcon />
          Back
        </Button>
        <Button
          className="btn primary"
          onClick={nextPage}
          configColors={colors}
        // disabled={index === steps.length - 1}
        >
          Next
          <ForwardIcon />
        </Button>
      </div>
    );
  };

  const style = {
    color: configColors.text,
    backgroundColor: mcaBGColor || configColors.background
    // backgroundColor: embed ? eaBGColor || configColors.background : mcaBGColor || configColors.background,
    // color: embed && eaTextColor ? eaTextColor : configColors.text
  };

  const stepStyle = {
    color: mcaStepHeadlineColor || configColors.text,
    fontFamily: mcaStepHeadlineFont,
    fontSize: mcaStepHeadlineFontSize
  };

  const titleStyle = {
    color: mcaStepDescriptionColor || configColors.text,
    fontFamily: mcaStepDescriptionFont,
    fontSize: mcaStepDescriptionFontSize
  };

  const instructionStyle = {
    color: mcaInstructionColor || configColors.background,
    fontSize: mcaInstructionFontSize,
    fontFamily: mcaInstructionFont
  };

  return (
    <div className="content-screen">
      <div className="container mb-32" style={style}>
        <p className="step mb-32" style={stepStyle}>
          {name}
        </p>
        <p className="title mb-12" style={titleStyle}>
          {title}
        </p>
        <p className="subtitle mb-12" style={instructionStyle}>
          {RenderEscapedString(description)}
        </p>
        <div className='preview-wrapper'>
          <PreviewCanvas configColors={configColors} embed={embed} id={id} step={currPage} onChange={onChange} />
        </div>
      </div>
      {renderActionBtn()}
    </div>
  );
};

export default Screen;
