import React, { useState } from 'react';

const DomainInput = ({ onChange, errorMsg, isCreating, domainName }) => {
  const [name, setName] = useState(domainName || '');
  const [invalidSubdomain, setInvalidSubdomain] = useState(false);

  const handleChange = (e) => {
    const validation = validate(e.target.value);
    setInvalidSubdomain(!validation);
    setName(e.target.value);
    onChange({ value: e.target.value, valid: validation });
  };

  const validate = (value) => {
    const regex = /^(?!http:\/\/|https:\/\/|www\.)([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$/;
    return regex.test(value);
  };

  return (
    <>
      <input
        type="text"
        placeholder="Enter subdomain e.g. example.domain.com"
        className="form-control"
        value={domainName || name}
        onChange={handleChange}
        autoFocus
      />
      {invalidSubdomain && (
        <p className="text-danger">
          The subdomain / domain should have the format{' '}
          <b>subdomain.domain.com</b> / <b>domain.com</b>
        </p>
      )}
      {errorMsg && <p className="text-danger">{errorMsg}</p>}
    </>
  );
};

DomainInput.defaultProps = {
  onChange: () => { }
};

export default DomainInput;
